<template>
<div v-if="!loading">
  <div class="product-container" >
    <div class="cards">
      <div v-for="elements in product._embedded.productList" :key="elements.id">
        <div class="card" @click="displayModal(elements)">
          <div class="container">
              <img :src="'/img/' + elements.picture" alt="test">
          </div>
          <div class="type">
            <img src="../../../assets/img/cow.png" alt="test" class="icon" v-if="elements.type === 'Boeuf'">
            <img src="../../../assets/img/chicken.png" alt="test" class="icon" v-if="elements.type === 'Poulet'">
            <img src="../../../assets/img/pig.png" alt="test" class="icon" v-if="elements.type === 'Porc'">
          </div>
          <div class="details">
            <h3>{{ elements.title }}</h3>
            <p> {{ elements.shortDescription }}</p>
          </div>
          <div class="price_container">
            <div class="price">{{ elements.price }}</div>
            <div class="addToCart">Ajouter au panier</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<product-modal v-if="showModal" @closeModal="hideModal" :title="title" :longDescription="longDescription" :price="price" :picture="picture"/>
</template>

<script>
import axios from 'axios';
import productModal from './productModal.vue';

export default {
  components: {
    productModal,
  },
  data() {
    return {
      product: [],
      loading: true,
      showModal: false,
      title: '',
      longDescription: '',
      price: '',
      picture: '',

    };
  },
  methods: {
    displayModal(product) {
      this.title = product.title;
      this.longDescription = product.longDescription;
      this.price = product.price;
      this.picture = product.picture; // eslint-disable-line prefer-template
      this.showModal = true;
    },

    hideModal() {
      this.showModal = false;
    },
  },

  async created() {
    await this.$store.dispatch('auth/refreshSession');
    await axios({
      method: 'get',
      url: 'https://jesirs95zl.execute-api.us-east-1.amazonaws.com/api/products',
      headers: {
        Authorization: localStorage.getItem('jwtToken'), // eslint-disable-line prefer-template
      },
    })
      .then((response) => {
        console.log('Produit ', response);
        this.product = response.data;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style scoped>

h2 {
    margin-left: 3rem;
}

p {
  white-space: pre-wrap;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  display: inline-block;
  height: 700px;
  position: relative;
  background-color: #1c1b29;
  border-radius: 20px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.18);
  max-width: 375px;
  margin: 10px;
}

.container {
    position: relative;
    height: 300px;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
}

img {
    width: 100%;
    height: 300px;
    display: block;
    border-radius: 20px 20px 0 0;
}

.container:after{
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 20px 20px 0 0;
    opacity: 0.7;
}

.details {
  height: 250px;
  padding: 20px 10px;

}

.details > h3 {
    color: #ffffff;
    font-weight: 600;
    font-size: 18;
    margin: 10px 0 15px 0;
}

.details > p {
    color: #a0a0a0;
    font-size: 15px;
    line-height: 30px;
    font-weight: 400;
}

.price_container {
  height: 100px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 10px;
}

.price {
    background-color: #00a015;
    color: #fff;
    font-size: 125%;
    display: flex;
    align-items: center;
    padding: 0 1em;
    height: 1.75em;
    font-weight: 700;
    border-radius: 80px;
    border: none;
    line-height: 1.75em;
}

.type {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}

.icon {
    position: relative;
    width: 50px;
    height: 50px;
}

.addToCart {
    background-color: #03256e;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 110%;
    padding: 0 1em;
    height: 2em;
    font-weight: 700;
    border-radius: 80px;
    border: none;
    line-height: 1.75em;
}

</style>
