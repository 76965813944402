import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,
  state() {
    return {
      id: '',
      email: '',
      firstName: '',
      lastName: '',
      gender: '',
      street: '',
      city: '',
      postalCode: '',
      province: '',
      phoneNumber: '',
      role: '',
      userLoggedIn: false,
      jwtToken: '',
    };
  },
  mutations,
  getters,
  actions,
};
