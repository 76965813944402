<template>
<news-bar />
  <nav-bar />
    <header>
      <div class="home_hero">
          <h2>Un retour aux sources </h2>
          <p>Nous sommes fiers de vous offrir de la viande de qualité supérieure provenant d’animaux nourris à l’herbe ou élevés en pâturage. </p>
          <p>Cette viande, difficilement accessible, vous sera livrée directement à votre porte!</p>
          <p>Notre santé est importante, prenons-nous en main dès maintenant</p>
          <button class="button_cta" @click="this.$router.push('/auth')">M'inscrire</button>
      </div>
    </header>

    <section class="whyMadnoom">
      <div class="row">
        <div class="column left whoIs">
          <h2>POURQUOI MADNOOM?</h2>
          <p>Pour manger une viande de qualité supérieure sans les casse-têtes pour se la procurer.</p>
        </div>
        <div class="column right whoIs">
          <p>
           Nous vivons présentement dans un monde ou tout n’est que performance. Peu importe le domaine, nous devons trouver tous les moyens possibles pour être plus productifs de jour en jour.
           Le monde de l’alimentation ne fait pas exception à la règle. La science et l’expérience de certains agriculteurs ont permis à ceux-ci de rendre leurs animaux plus « performants ».
           En les nourrissant avec des céréales plutôt que de l’herbe, les bêtes engraissent plus rapidement, augmentant ainsi les profits.
          </p>
          <p>
            Mais, avons-nous évaluer les impacts de cette viande sur notre santé? Est-ce ce qu’il y a de mieux pour moi? Évidemment, la réponse est non.
            Un mouvement commence d’ailleurs à prendre de plus en plus d’ampleur : des animaux nourris à l’herbe ou élevés en pâturage. Comme cette tendance ne peut être reproduite par les mégas éleveurs de ce monde, il est difficile de se procurer cette viande.
            Et bien, plus maintenant! Madnoom vous livrera directement à votre domicile de la viande provenant uniquement d’animaux nourris et finis à l’herbe ou d’animaux élevés en pâturage.
          </p>
        </div>
      </div>
    </section>

    <section class="grassFed">
      <h2>Quels sont les impacts des animaux nourris à l'herbe</h2>
      <p>Qui de mieux pour nous l'expliquer que Paul de la ferme Grazing Days, notre principal fournisseur.</p>
      <div class="embedded_video">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/EB1nxq5owtM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <p>Voici les aspects positifs sur votre santé à la consommation de boeuf nourri à l'herbe. </p>
      <div class="image_full">
         <img src="../assets/img/grassfedvsgrainfed.jpg" alt="test"/>
      </div>

    </section>

    <section class="offer">
        <h2>Ce que nous offrons</h2>
        <div class="row_offer">
          <div class="column images_home">
            <img src="../assets/img/meat.jpg" alt="test"/>
            <h2>De la viande de qualité supérieure, savoureuse et nutritive</h2>
          </div>
          <div class="column images_home">
            <img src="../assets/img/5star2.jpg" alt="test"/>
            <h2>Un service de livraison 5 étoiles</h2>
          </div>
      </div>
      <button class="button_cta" @click="this.$router.push('/auth')">M'inscrire</button>
    </section>

    <section class="whatIsMadnoom">
      <h2>Qu'est ce que Madnoom</h2>
      <div class="row">
        <div class="fourColumn">
          <h3>DES VIANDES NOURRIES À L'HERBE</h3>
          <p>Toutes les viandes que vous recevrez proviendrons d'animaux nourris et finis à l'herbe ou élevés en paturage</p>
        </div>
        <div class="fourColumn">
          <img src="../assets/img/grassfedlogo.jpg" alt="test"/>
        </div>
        <div class="fourColumn">
          <img src="../assets/img/solution.jpg" alt="test"/>
        </div>
        <div class="fourColumn">
          <h3>DES PRODUITS BON POUR LA SANTÉ</h3>
          <p>Tous les produits offerts sont nutritifs et regorgent de bonnes protéines. </p>
        </div>
      </div>
      <div class="row">
        <div class="fourColumn">
          <h3>AUCUNE MARGE SUR LES PRODUITS</h3>
          <p>Madnoom ne prend aucune marge sur les produits livrés, seul un frais unique de gestion est chargé pour la livraison à votre domicile</p>
        </div>
        <div class="fourColumn">
          <img src="../assets/img/gratuit.jpg" alt="test"/>
        </div>
        <div class="fourColumn">
          <img src="../assets/img/no.jpg" alt="test"/>
        </div>
        <div class="fourColumn">
          <h3>SANS AUCUN CONTRAT</h3>
          <p>Aucun contrat! Aucun engagement!</p>
        </div>
      </div>
    </section>

    <section class="contact">
        <h2>MADNOOM</h2>
        <p>De la viande naturelle de haute qualité livrée directement à votre domicile</p>
        <button class="button_cta" @click="this.$router.push('/auth')">M'inscrire</button>
    </section>

  <!-- Footer -->
    <footer id="footer">
      <div class="container">
        <!-- <ul class="icons">
          <li><a href="#" class="icon fa-facebook"></a></li>
          <li><a href="#" class="icon fa-twitter"></a></li>
          <li><a href="#" class="icon fa-instagram"></a></li>
        </ul> -->
        <ul class="copyright">
          <li>&copy; Madnoom</li>
          <li>Design: Madnoom</li>
          <!-- <li>Images: Madnoom</li> -->
        </ul>
      </div>
    </footer>
</template>

<script>
import NavBar from '../components/UI/NavBar.vue';
import NewsBar from '../components/UI/NewsBar.vue';

export default {
  name: 'Home',

  components: {
    NavBar,
    NewsBar,
  },

  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped>

</style>
