export default {

  login(state) {
    state.userLoggedIn = true;
  },

  logout(state) {
    state.userLoggedIn = false;
  },

  setJwtToken(state, payload) {
    state.jwtToken = payload;
  },

  setUserPrimaryInfo(state) {
    state.email = localStorage.getItem('email');
    state.firstName = localStorage.getItem('firstName');
    state.lastName = localStorage.getItem('lastName');
  },

  setUser(state) {
    state.email = localStorage.getItem('email');
    state.firstName = localStorage.getItem('firstName');
    state.lastName = localStorage.getItem('lastName');
    state.gender = localStorage.getItem('Gender');
    state.city = localStorage.getItem('City');
    state.phoneNumber = localStorage.getItem('PhoneNumber');
    state.postalCode = localStorage.getItem('PostalCode');
    state.province = localStorage.getItem('Province');
    state.street = localStorage.getItem('Street');
    state.role = localStorage.getItem('Role');
    state.id = localStorage.getItem('Id');
  },

  setUserPersonalInfo(state) {
    state.gender = localStorage.getItem('Gender');
    state.city = localStorage.getItem('City');
    state.phoneNumber = localStorage.getItem('PhoneNumber');
    state.postalCode = localStorage.getItem('PostalCode');
    state.province = localStorage.getItem('Province');
    state.street = localStorage.getItem('Street');
    state.role = localStorage.getItem('Role');
    state.id = localStorage.getItem('Id');
  },

  updateUserInfo(state, payload) {
    state.gender = payload.gender;
    state.city = payload.city;
    state.phoneNumber = payload.phoneNumber;
    state.postalCode = payload.postalCode;
    state.province = payload.province;
    state.street = payload.street;
    state.role = payload.role;
    localStorage.setItem('Gender', payload.gender);
    localStorage.setItem('City', payload.city);
    localStorage.setItem('PhoneNumber', payload.phoneNumber);
    localStorage.setItem('PostalCode', payload.postalCode);
    localStorage.setItem('Province', payload.province);
    localStorage.setItem('Street', payload.street);
    localStorage.setItem('Role', payload.role);
  },

  resetUserPersonalInfo(state) {
    state.email = null;
    state.firstName = null;
    state.lastName = null;
    state.gender = null;
    state.city = null;
    state.phoneNumber = null;
    state.postalCode = null;
    state.province = null;
    state.street = null;
    state.role = null;
    state.id = null;
  },

};
