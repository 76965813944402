<template>
<div class="member_list">
    <div class="title_row">
        <div class="headers">
            <p>ID</p>
            <span>&#8593;</span>
            <span>&#8595;</span>
        </div>
        <div class="headers">
            <p>Prénom</p>
            <span>&#8593;</span>
            <span>&#8595;</span>
        </div>
        <div class="headers">
            <p>Nom</p>
            <span>&#8593;</span>
            <span>&#8595;</span>
        </div>
         <div class="headers">
            <p>Courriel</p>
            <span>&#8593;</span>
            <span>&#8595;</span>
        </div>
        <div class="headers phoneNumber">
            <p>Numéro de téléphone</p>
            <span>&#8593;</span>
            <span>&#8595;</span>
        </div>
        <div class="headers">
            <p>Adresse</p>
            <span>&#8593;</span>
            <span>&#8595;</span>
        </div>
        <div class="headers">
            <p>Date</p>
            <span>&#8593;</span>
            <span>&#8595;</span>
        </div>
        <div class="headers">
            <p>Status</p>
            <span>&#8593;</span>
            <span>&#8595;</span>
        </div>
        <div class="headers">
            <p>Role</p>
            <span>&#8593;</span>
            <span>&#8595;</span>
        </div>
        <div class="headers">
            <p>Action</p>
            <span>&#8593;</span>
            <span>&#8595;</span>
        </div>
    </div>
    <div v-if="!loading">
        <div v-for="elements in member._embedded.memberList" :key="elements.id" class="member_row">
            <div class="member">
                <p>{{ elements.id }}</p>
            </div>
            <div class="member">
                <p>{{ elements.firstName}}</p>
            </div>
            <div class="member">
                <p>{{ elements.lastName}}</p>
            </div>
            <div class="member">
                <p>{{ elements.email}}</p>
            </div>
            <div class="member">
                <p>{{ elements.phoneNumber}}</p>
            </div>
            <div class="member">
                <p>{{ elements.street}}, {{ elements.city }}, {{ elements.postalCode }}, {{ elements.province }}</p>
            </div>
            <div class="member">
                <p> {{ elements.createdDate }}</p>
            </div>
            <div class="member">
                <p> {{ elements.status }}</p>
            </div>
            <div class="member">
                <p> {{ elements.role }}</p>
            </div>
        </div>
    </div>
    <div class="loader" v-if="loading"></div>
</div>
</template>

<script>
// import { Auth } from 'aws-amplify';
import axios from 'axios';

export default {
  data() {
    return {
      member: [],
      loading: true,
      display: false,
    };
  },
  methods: {},

  async created() {
    await this.$store.dispatch('auth/refreshSession');
    await axios({
      method: 'get',
      url: 'https://jesirs95zl.execute-api.us-east-1.amazonaws.com/api/members',
      headers: {
        Authorization: localStorage.getItem('jwtToken'), // eslint-disable-line prefer-template
      },
    })
      .then((response) => {
        this.member = response.data;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => { this.loading = false; });
  },
};
</script>

<style scoped>

p {
    color: #6c7293;
    margin-bottom: 0;
    margin-right: 0.8rem;
}

span {
    color: #6c7293;
}

.member_list {
    /* width: calc(100vw - 356px); */
    /* margin: 1rem; */
    width: 100%;
    overflow: auto;
    background-color: #191c24;
}

.title_row,
.member_row {
    border-bottom: 1px solid #2c2e33;
    margin: 1rem 0;
    display: grid;
    height: 70px;
    /* grid-template-columns: repeat(9, minmax(150px, 1fr)); */
    grid-template-columns:
    minmax(100px, 0.5fr) minmax(150px, 1fr) minmax(150px, 1fr)
    minmax(250px, 2fr) minmax(250px, 1fr) minmax(200px, 1fr)
    minmax(200px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr)
    minmax(150px, 1fr);
    grid-auto-flow: column;
}

.headers,
.member {
    display: flex;
    justify-content: center;
    align-items: center;
}

</style>
