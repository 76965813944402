import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/index';
// import { Auth } from 'aws-amplify';
import Home from '../views/Home.vue';
import GrassFed from '@/views/GrassFed.vue';
import Register from '@/views/Register.vue';
import Admin from '@/views/Admin.vue';
import Dashboard from '@/views/Dashboard.vue';
import Product from '@/views/Product.vue';
import Projet from '@/views/Project.vue';
import Auth from '@/views/Auth.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/grassfed',
    name: 'grassFed',
    component: GrassFed,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: { requiresUserInfo: true },
  },
  {
    path: '/projet',
    name: 'projet',
    component: Projet,
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: '/product',
    name: 'product',
    component: Product,
  },
  {
    path: '/auth',
    name: 'auth',
    component: Auth,
    meta: { requiresUnauth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, _, next) => {
  if (to.meta.requiresAuth && !store.getters['auth/IS_USER_AUTHENTICATED']) {
    next('/login');
  } else if (to.meta.requiresUnauth && store.getters['auth/IS_USER_AUTHENTICATED']) {
    next('/dashboard');
  } else if (to.meta.requiresUserInfo && store.getters['auth/USER_PHONENUMBER'] !== '') {
    next('/dashboard');
  } else {
    next();
  }
});

export default router;
