<template>
<nav-bar />
<div class="login_page"></div>
  <div class="login_wrapper">
    <div class="container">
      <div class="authenticator">
        <!-- <div class="tabs" v-if="displayLogin || displaySignup"> -->
          <div class="tabs" v-if="display === 'Login' || display === 'Signup'">
          <div @click="loginSelected"
          :class="{ active_tab: display === 'Login', tab: display !== 'Login'}">
          Se connecter</div>
          <div @click="signupSelected"
          :class="{ active_tab: display === 'Signup', tab: display !== 'Signup'}">
          S'enregistrer</div>
        </div>
          <div class="tabcontent">
            <form class="login" @submit.prevent="login" v-if="display === 'Login'">
              <input type="email" id="email" name="email" placeholder="Email" v-model="user.username"/>
              <input type="password" id="password" name="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" placeholder="Mot de passe" v-model="user.password"/>
              <button class="submit_button">Enregistrer</button>
            </form>
            <form class="login" @submit.prevent="signup" v-if="display === 'Signup'">
              <input type="email" id="email" name="email" placeholder="Email" v-model="user.username"/>
              <input type="password" id="password" name="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" placeholder="Mot de passe" v-model="user.password"/>
              <input type="password" id="ComparePassword" name="password" placeholder="Confirmer le mot de passe" v-model="confirmPassword"/>
              <input type="text" id="firstname" name="firstname" placeholder="Prénom" v-model="user.firstname"/>
              <input type="text" id="lastname" name="lastname" placeholder="Nom de famille" v-model="user.lastname"/>
              <button class="submit_button">Enregistrer</button>
            </form>
            <form class="login" @submit.prevent="ConfirmSignUp" v-if="display === 'CodeVerification'">
              <label class="title">Nous venons de vous envoyer un courriel</label>
              <p>Veuillez entrer le code reçu par courriel. Il se peut que le courriel prenne quelques minutes à arriver</p>
              <input type="text" id="code" name="code" placeholder="Saisissez votre code de vérification" v-model="user.code"/>
              <button class="submit_button" type="submit">Confirmer</button>
              <button class="resend_button" type="button" @click="sendNewCode">Renvoyer le code</button>
            </form>
            <form class="login" @submit.prevent="forgotPassword" v-if="display === 'ForgotPassword'">
              <label class="title">Réinitialisez votre mot de passe</label>
              <input type="email" id="email" name="email" placeholder="Saisissez votre adresse courriel" v-model="user.username"/>
              <button class="submit_button">M'envoyer un code</button>
            </form>
            <form class="login" @submit.prevent="resetPassword" v-if="display === 'ResetPassword'">
              <label class="title">Choisir un nouveau mot de passe</label>
              <input type="text" id="code" name="code" placeholder="Code" v-model="user.code"/>
              <input type="password" id="NewPassword" name="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" placeholder="Nouveau mot de passe" v-model="user.password"/>
              <input type="password" id="ComparePassword" name="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" placeholder="Confirmer le mot de passe" v-model="confirmPassword"/>
              <button class="submit_button">Enregistrer</button>
            </form>
            <div class="warning" v-if="passwordNotMatching">Les mots de passe ne correspondent pas</div>
            <div class="warning" v-if="message === 'InvalidCode'">Le code entré est invalide, veuillez recommencer</div>
            <div class="warning" v-if="message === 'ExpiredCode'">Le code entré a expiré, veuillez redemander un nouveau code</div>
            <div class="warning" v-if="message === 'LimitExceeded'">La requête a été faite trop souvent, veuillez recommener plus tard</div>
            <div class="warning" v-if="message === 'InvalidPassword'">Le mot de passe ne contient pas tous les éléments requis. Veuillez recommencer</div>
            <div class="warning" v-if="message === 'NotAuthorizedException'">Le mot de passe ou l'addresse courriel est invalide. Veuillez recommencer</div>
            <div class="warning" v-if="message === 'InvalidParameterException'">Le mot de passe ne peut pas être vide. Veuillez recommencer</div>
            <div class="message" v-if="message === 'InvalidParameterExceptionName'">Vous devez remplir tous les champs.</div>
            <div class="warning" v-if="message === 'AuthError'">Le nom d'usager ou l'adresse courriel ne peuvent pas être vide. Veuillez recommencer</div>
            <div class="message" v-if="message === 'Success'">Le mot de passe à bien été réinitialisé.</div>
            <div class="password" @click="displayForgotPassword" v-if="display === 'Login'">Mot de passe oublié ?</div>
            <div class="password" @click="displayLoginScreen" v-if="display === 'ForgotPassword'">Retour à la connection</div>
            <div class="password" @click="displayLogin" v-if="display === 'ResetPassword'">Renvoyer le code</div>
            <div class="passwordHelp" v-if="display === 'Signup' || display === 'ResetPassword'">
            <p>Le mot de passe doit contenir au minimum: </p>
              <div class="attributes">
                <!-- <img src="../assets/img/check.png" alt="test" class="icon" v-if="passUpperCase"/>
                <img src="../assets/img/close.png" alt="test" class="icon" v-else/> -->
                <p>Une lettre majuscule</p>
              </div>
              <div class="attributes">
                <!-- <img src="../assets/img/check.png" alt="test" class="icon" v-if="passLowerCase"/>
                <img src="../assets/img/close.png" alt="test" class="icon" v-else/> -->
                <p>Une lettre minuscule</p>
              </div>
              <div class="attributes">
                <!-- <img src="../assets/img/check.png" alt="test" class="icon" v-if="passNumber"/>
                <img src="../assets/img/close.png" alt="test" class="icon" v-else/> -->
                <p>Un chiffre</p>
              </div>
              <div class="attributes">
                <!-- <img src="../assets/img/check.png" alt="test" class="icon" v-if="passCharacter"/>
                <img src="../assets/img/close.png" alt="test" class="icon" v-else/> -->
                <p>Un minimum de 8 caractères</p>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '../components/UI/NavBar.vue';

export default {
  components: { NavBar },
  data() {
    return {
      confirmPassword: '',
      display: 'Login',
      displayPasswordHelp: true,
      passLowerCase: false,
      passUpperCase: false,
      passNumber: false,
      passCharacter: false,
      numberOfChar: 0,
      message: '',
      newPassword: [],
      user: {
        username: '',
        password: '',
        email: '',
        code: '',
        firstname: '',
        lastname: '',
      },
      passwordNotMatching: false,
    };
  },
  methods: {
    signupSelected() {
      this.display = 'Signup';
      this.message = '';
    },
    loginSelected() {
      this.display = 'Login';
      this.message = '';
    },
    displayForgotPassword() {
      this.display = 'ForgotPassword';
      this.message = '';
    },
    displayLoginScreen() {
      this.display = 'Login';
      this.message = '';
    },

    // passwordValidation(input) {
    //   if (input.keyCode !== 16) {
    //     // console.log(parseInt(input.key, 10));
    //     if (input.keyCode === 8 || input.keyCode === 46) {
    //       this.newPassword.splice(-1, 1);
    //       this.passUpperCase = false;
    //       this.passLowerCase = false;
    //       this.passNumber = false;
    //     } else {
    //       this.newPassword.push(input.key);
    //     }
    //     this.newPassword.forEach((pass) => {
    //       console.log(parseInt(pass, 10));
    //       if (parseInt(pass, 10) !== 'NaN') {
    //         this.passNumber = true;
    //       } else if (pass === pass.toUpperCase()) {
    //         this.passUpperCase = true;
    //       } else if (pass === pass.toLowerCase()) {
    //         this.passLowerCase = true;
    //       }
    //       console.log(pass);
    //     });
    //   }
    // },

    async login() {
      await this.$store.dispatch('auth/signIn', this.user)
        .then((response) => {
          const message = JSON.stringify(response);
          if (message.indexOf('NotAuthorizedException') > 0) {
            this.message = 'NotAuthorizedException';
          } else if (message.indexOf('InvalidParameterException') > 0) {
            this.message = 'InvalidParameterException';
          } else if (message.indexOf('AuthError') > 0) {
            this.message = 'AuthError';
          } else if (message.indexOf('UserNotConfirmedException') > 0) {
            this.display = 'CodeVerification';
          } else if (message.indexOf('emailNotFoundInDB') > 0) {
            this.$router.push('/register');
          } else {
            this.$router.push('/dashboard');
          }
        })
        .catch((erreur) => {
          console.log(erreur);
        });
    },
    async signup() {
      if (this.user.password !== this.confirmPassword) {
        this.passwordNotMatching = true;
      } else {
        this.passwordNotMatching = false;
        await this.$store.dispatch('auth/signup', this.user)
          .then((response) => {
            const erreur = JSON.stringify(response);
            if (erreur.indexOf('InvalidParameterException') > 0) {
              this.message = 'InvalidParameterExceptionName';
            } else if (erreur.indexOf('AuthError') > 0) {
              this.message = 'AuthError';
            } else {
              this.display = 'CodeVerification';
            }
          });
      }
    },
    async ConfirmSignUp() {
      await this.$store.dispatch('auth/confirmSignup', this.user)
        .then((response) => {
          const erreur = JSON.stringify(response);
          if (erreur.indexOf('SUCCESS') > 0) {
            console.log('Votre enregistrement est complété');
            this.login();
          } else if (erreur.indexOf('ExpiredCodeException') > 0) {
            this.message = 'ExpiredCode';
          } else if (erreur.indexOf('CodeMismatchException') > 0) {
            this.message = 'InvalidCode';
          } else if (erreur.indexOf('LimitExceededException') > 0) {
            this.message = 'LimitExceeded';
          } else if (erreur.indexOf('InvalidPasswordException') > 0) {
            this.message = 'InvalidPassword';
          }
        });
    },
    async forgotPassword() {
      await this.$store.dispatch('auth/forgotPassword', this.user.username);
      this.display = 'ResetPassword';
    },
    async resetPassword() {
      if (this.user.password !== this.confirmPassword) {
        this.passwordNotMatching = true;
      } else {
        this.passwordNotMatching = false;
        await this.$store.dispatch('auth/recoverPassword', this.user)
          .then((response) => {
            const erreur = JSON.stringify(response);
            if (erreur.indexOf('ExpiredCodeException') > 0) {
              this.message = 'ExpiredCode';
            } else if (erreur.indexOf('CodeMismatchException') > 0) {
              this.message = 'InvalidCode';
            } else if (erreur.indexOf('LimitExceededException') > 0) {
              this.message = 'LimitExceeded';
            } else if (erreur.indexOf('InvalidPasswordException') > 0) {
              this.message = 'InvalidPassword';
            } else if (response === 'SUCCESS') {
              this.message = 'Success';
              this.display = 'Login';
            }
          })
          .catch((err) => {
            console.log(err);
            console.log('mauvaise place');
          });
      }
    },
    async sendNewCode() {
      const test = this.$store.dispatch('auth/sendNewCode', this.user.username);
      console.log('New code sent', test);
    },
  },
};
</script>

<style scoped>

.login_wrapper {
  height: 100vh;
  background-image: url("../assets/img/animal.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom left;
}

.container {
  padding-top: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.authenticator {
  width: 30rem;
}

.active_tab {
  width: 50%;
  float: left;
  cursor: pointer;
  padding: 12px 24px;
  background-color: #fff;
  font-weight: bold;
  color: #006100;
  text-align: center;
  border-top: 2px solid #006100;
}

.tab {
  width: 50%;
  float: left;
  cursor: pointer;
  padding: 12px 24px;
  background-color: #FAFAFA;
  font-weight: bold;
  color: #006100;
  text-align: center;
  border-top: none;
}

/* Style the tab content */
.tabcontent {
  padding: 30px;
  background-color: #fff;
}

.login {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: 1rem 0 1rem 0;
}

input {
  width: 100%;
  font-family: inherit;
  font-size: 1.2em;
  line-height: 1.5em;
  padding: 8px;
}

.submit_button {
  margin-top: 1rem;
  background-color: #006100;
  color: #fff;
  font-size: 1.35em;
  display: inline-block;
  text-align: center;
  padding: 0 2.2em;
  height: 2.15em;
  font-weight: 700;
  cursor: pointer;
  border-radius: 4px;
  border: none;
}

.resend_button {
  background-color: #fff;
  color: #000;
  font-size: 1.35em;
  display: inline-block;
  text-align: center;
  padding: 0 2.2em;
  height: 2.15em;
  font-weight: 700;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #000;
}

.password {
  text-align: center;
  color: #006100;
  cursor: pointer;
}

.title {
  font-size: 150%;
  padding-bottom: 1rem;
}
.warning {
  color: red;
  padding-bottom: 1rem;
  text-align: center;
}

.message {
  color: #006100;
  padding-bottom: 1rem;
  text-align: center;
}

.passwordHelp > p{
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.icon {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.attributes {
  display: flex;
}

.attributes > p {
  margin-bottom: 0;
  font-weight: bold;
}

.green {
  color: #006100;
}

.red {
  color: red;
}

.gender {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

@media (max-width: 775px) {

.authenticator {
  width: 20rem;
}

.container {
  padding-top: 5rem;
}

input {
  font-size: 0.8em;
  line-height: 1em;
  padding: 4px;
}

.passwordHelp > p{
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}
.title {
  padding-top: 10px;
}

.resend_button {
  font-size: 1em;
}

.submit_button {
  margin-top: 0;
  font-size: 1em;
}

}

</style>
