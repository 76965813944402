<template>
<div class="modal">
    <div class="modal_container">
        <div v-if="!image" class="modal_image_success"></div>
        <div v-else class="modal_image_err"></div>
        <p>{{message}}</p>
        <button class="submit_button" @click="closeModal">{{buttonMessage}}</button>
    </div>
</div>
</template>

<script>
export default {
  name: 'Modal',
  props: ['message', 'image', 'buttonMessage'],
  data() {
    return {};
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
  },
};
</script>

<style scoped>
.modal {
    position:fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    opacity: 1;
    /* visibility: hidden; */
}
  .modal_container {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 199;
    width: 600px;
    height: auto;
    padding: 0 2rem;
    padding-bottom: 2rem;
    overflow: hidden;
    background-color: #000;
    border-radius: 20px;
    box-shadow: rgba(112,128,175,0.2)0px 16px 24px 0px;
    border: 2px solid #9A94A4;
    text-align: justify;
  }

  .modal_image_err {
    margin: 2rem 0;
    height: 10vh;
    background-image: url("../../assets/img/exclamationPoint.gif");
    background-position: center center;
    background-size: 100px;
    background-repeat: no-repeat;
    line-height: 1.75;
    text-align: center;
  }

  .modal_image_success {
    margin: 2rem 0;
    height: 10vh;
    background-image: url("../../assets/img/checkMark.gif");
    background-position: center center;
    background-size: 100px;
    background-repeat: no-repeat;
    line-height: 1.75;
    text-align: center;
  }

  p {
    color: white;
    line-height: 1;
    font-size: 1.2rem;
  }

  .submit_button {
  margin-top: 1rem;
  background-color: #006100;
  color: #fff;
  font-size: 1.35em;
  display: inline-block;
  text-align: center;
  padding: 0 2.2em;
  height: 2.15em;
  font-weight: 700;
  cursor: pointer;
  border-radius: 4px;
  border: none;
}
</style>
