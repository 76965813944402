import { createStore } from 'vuex';

import authModule from './module/auth/index';
import cartModule from './module/cart/index';

const store = createStore({
  modules: {
    auth: authModule,
    cart: cartModule,
  },

  state: {

  },
  getters: {

  },
  mutations: {

  },
  actions: {

  },
});

export default store;
