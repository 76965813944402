<template>
<div class="wrapper">
  <div class="cards">
    <div class="card">
      <div class="container">
        <div class="left_side">
          <img src="../../../assets/img/calendar.png" alt="test" class="icon">
        </div>
        <div class="right_side">
          <p>Date limite de commande</p>
          <p>31 Mars</p>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="container">
        <div class="left_side">
          <img src="../../../assets/img/delivery.png" alt="test" class="icon">
        </div>
        <div class="right_side">
          <p>Date de livraison Mont-Laurier</p>
          <p>8 Avril</p>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="container">
        <div class="left_side">
          <img src="../../../assets/img/delivery.png" alt="test" class="icon">
        </div>
        <div class="right_side">
          <p>Date de livraison Gatineau</p>
          <p>9 Avril</p>
        </div>
      </div>
    </div>
  </div>
</div>

</template>

<script>
export default {

};
</script>

<style scoped>

p {
  color: #fff;
  margin: 0 0 5px 0;
}
.cards {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-basis: auto;
  gap: 20px;
}

.card {
  flex: 1;
  height: 100px;
  position: relative;
  background-color: #1c1b29;
  border-radius: 20px;
  min-width: 300px;
}

.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.left_side {
  padding-left: 10px;
}

.right_side {
  /* max-width: 250px; */
  padding-right: 10px;
  text-align: center;
}

.icon {
  width: 40px;
  height: 40px;
}

</style>
