export default {

  IS_USER_AUTHENTICATED(state) {
    return state.userLoggedIn;
  },

  JWT_TOKEN(state) {
    return state.jwtToken;
  },

  USER_EMAIL(state) {
    return state.email;
  },

  USER_FIRSTNAME(state) {
    return state.firstName;
  },

  USER_LASTNAME(state) {
    return state.lastName;
  },

  USER_GENDER(state) {
    return state.gender;
  },

  USER_STREET(state) {
    return state.street;
  },

  USER_CITY(state) {
    return state.city;
  },

  USER_POSTALCODE(state) {
    return state.postalCode;
  },

  USER_PROVINCE(state) {
    return state.province;
  },

  USER_PHONENUMBER(state) {
    return state.phoneNumber;
  },

  USER_ROLE(state) {
    return state.role;
  },

  USER_ID(state) {
    return state.id;
  },
};
