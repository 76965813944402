<template>
<div class="container">
  <p>Pour le projet pilote, les commandes se feront par courriel. Si vous êtes intéressé, communiquer avec nous au info@madnoom.ca</p>
  <p>Présentement seulement disponible pour la région de Mont-Laurier et Gatineau</p>
</div>
</template>

<style scoped>
  .container {
    background-color: red;
    text-align: center;
  }

  p {
    margin: 0;
    font-weight: bold;
  }
</style>
