<template>
  <div class="edit_wrapper">
    <form class="register_form" @submit.prevent="register">
      <div class="category">
        <label>Produit</label>
        <input type="text" id="name" name="name" placeholder="Nom du produit" v-model="editedProduct.name"/>
      </div>
      <div class="category">
        <label>Producteur</label>
        <input type="text" id="supplier" name="supplier" placeholder="Producteur"  v-model="editedProduct.supplier"/>
      </div>
      <div class="category">
        <label>Description (courte)</label>
        <textarea id="shortDescription" name="shortDescription" placeholder="Description courte"  v-model="editedProduct.shortDescription"/>
      </div>
      <div class="category">
        <label>Description (longue)</label>
        <textarea id="longDescription" name="longDescription" placeholder="Description longue" v-model="editedProduct.longDescription"/>
      </div>
      <div class="category">
        <label>Titre</label>
        <input type="text" id="title" name="title" placeholder="Titre" v-model="editedProduct.title"/>
      </div>
      <div class="category">
        <label>Type</label>
        <input type="text" id="type" name="type" placeholder="Type de produit" v-model="editedProduct.type"/>
      </div>
      <div class="category">
        <label>Nom de la photo</label>
        <input type="tel" id="hdPics" name="hdPics" placeholder="Photo HD" v-model="editedProduct.picture"/>
      </div>
      <div class="category">
        <label>Nom du thumbnail</label>
        <input type="tel" id="thumbnails" name="thumbnails" placeholder="Photo basse résolution" v-model="editedProduct.thumbnails"/>
      </div>
      <div class="category">
        <label>Prix</label>
        <input type="tel" id="price" name="price" placeholder="Prix" v-model="editedProduct.price"/>
      </div>
      <div class="category">
        <label>Quantité</label>
        <input type="tel" id="quantity" name="quantity" placeholder="Quantité"  v-model="editedProduct.quantity"/>
      </div>
      <div class="category">
        <label>Status</label>
        <input type="tel" id="status" name="status" placeholder="Statut"  v-model="editedProduct.status"/>
      </div>
      <div class="button">
        <button class="submit_button">Enregistrer</button>
        <button class="cancel_button" @click="closeModal">Annuler</button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'editProduct',
  props: ['product'],
  components: {
  },
  data() {
    return {
      editedProduct: {
        name: this.product.name,
        supplier: this.product.supplier,
        shortDescription: this.product.shortDescription,
        longDescription: this.product.longDescription,
        title: this.product.title,
        type: this.product.type,
        picture: this.product.picture,
        thumbnails: this.product.thumbnails,
        price: this.product.price,
        quantity: this.product.quantity,
        status: this.product.status,
      },
    };
  },

  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    async register() {
      await this.$store.dispatch('auth/refreshSession');
      const urlLink = 'https://jesirs95zl.execute-api.us-east-1.amazonaws.com/api/products/' + this.product.id; // eslint-disable-line prefer-template
      await axios({
        method: 'put',
        url: urlLink,
        headers: {
          Authorization: localStorage.getItem('jwtToken'), // eslint-disable-line prefer-template
        },
        data: this.editedProduct,
      })
        .catch((error) => {
          console.log('Erreur: ', error);
        })
        .finally(() => {
          // this.$store.commit('auth/updateUserInfo', this.editedProduct);
          this.$router.go();
        });
    },
  },
};

</script>
<style scoped>
.edit_wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1500px;
  height: 800px;
  z-index: 2;
}

h2 {
  color: white;
}

.register_form {
  background-color: white;
  border-radius: 50px;
  padding: 5rem 5rem 2rem 5rem;
  width: 80%;
  min-width: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.register_form label {
  margin: 5px 30px 5px 0;
  color: #aaa;
  font-size: 1.2em;
  line-height: 1.5em;
}

input {
  width: 100%;
  border: none;
  border-bottom: 2px solid #ebebeb;
  margin-bottom: 31px;
  font-family: inherit;
  color: #aaa;
  font-size: 1.2em;
  line-height: 1.5em;
}

p {
  color: #aaa;
  font-size: 1.2em;
  line-height: 1.5em;
}

.button {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.submit_button {
  margin-top: 1rem;
  background-color: #006100;
  color: #fff;
  font-size: 1.35em;
  display: inline-block;
  text-align: center;
  padding: 0 1.2em;
  height: 2.15em;
  font-weight: 700;
  cursor: pointer;
  border-radius: 4px;
  border: none;
}

.cancel_button {
  margin-top: 1rem;
  background-color: #F72C2C;
  color: #fff;
  font-size: 1.35em;
  display: inline-block;
  text-align: center;
  padding: 0 1.2em;
  height: 2.15em;
  font-weight: 700;
  cursor: pointer;
  border-radius: 4px;
  border: none;
}

.category {
  width: 100%;
  display: flex;
}

label {
  width: 15%;
  font-weight: bold;
}

textarea {
  font-family: inherit;
  font-size: inherit;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  height: 100px;
}

 /* @media (min-width: 500px) {
  .register_form {
    width: 500px;
    min-width: 500px;
  }
 } */
</style>
