<template>
  <nav id="topbar" class="topbar">
      <div class="navbar-menu-wrapper">
        <div class="brand_name" v-if="displaySidebar">Madnoom</div>
          <div class="hamburger_sidebar" v-else @click="displaySideBar">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
          <ul class="navbar navbar-right">
              <li class="nav-profile">
                  <div class="profile_pic">
                  <img src="../../../assets/img/man.gif" alt="test" class="profile_icon" v-if="this.gender === 'homme'"/>
                  <img src="../../../assets/img/woman.gif" alt="test" class="profile_icon" v-if="this.gender === 'femme'"/>
                  <div class="profile_name">
                      <h5 class="member_name" @click="dropDown">{{ userName }}</h5>
                  </div>
              </div>
              </li>
          </ul>
      </div>
  </nav>
  <div class="sub-menu" v-if="dropDownMenu">
      <h5 class="member_name" @click="displayProfile">Profile</h5>
      <h5 class="member_name" @click="logout">Déconnection</h5>
  </div>
</template>
<script>
export default {
  emits: ['showProfile', 'showSidebar'],
  props: ['displaySidebar'],

  data() {
    return {
      dropDownMenu: false,
      gender: this.$store.getters['auth/USER_GENDER'],
    };
  },

  methods: {
    dropDown() {
      this.dropDownMenu = !this.dropDownMenu;
    },

    logout() {
      this.$store.dispatch('auth/logout');
      this.$router.push({ name: 'home' });
    },

    displayProfile() {
      this.dropDownMenu = false;
      this.displayDropDown = false;
      this.$emit('showProfile');
    },
    displaySideBar() {
      this.$emit('showSidebar');
    },
  },
  computed: {
    userName() {
      return this.$store.getters['auth/USER_FIRSTNAME'] + ' ' + this.$store.getters['auth/USER_LASTNAME']; // eslint-disable-line prefer-template
    },
  },
};
</script>

<style scoped>

html {
    overflow-x: hidden;
    -webkit-text-size-adjust: 100%;
}

body {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    text-align: left;
}

a {
    font-size: 1;
}

li {
    margin: 0;
}

.topbar {
  width: 100vw;
  font-weight: 400;
  background-color: #191c24;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
}

.navbar {
    list-style: none;
}

.navbar-menu-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #fff;
  padding-left: 15px;
  padding-right: 15px;
  height: 70px;
}

.profile_pic {
    display: flex;
    align-items: center;
}

.profile_icon {
    position: relative;
    width: 35px;
}

.profile_name {
    margin-left: 1rem;
    display: block;
    margin-right: 1rem;
    font-weight: 400;
}

.profile_name span {
    font-size: 12px;
    color: #6c7293;
    white-space: nowrap;
}

.member_name {
    white-space: nowrap;
    color: #fff;
    margin: 0;
    line-height: 1.2;
    font-size: 1rem;
    cursor: pointer;
}

.menu-icon {
    margin-right: 1rem;
    font-size: .8125rem;
    background-color: rgba(108,114,147,.2);
    width: 31px;
    height: 31px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.icon {
    width: 24px;
    height: 24px;
}

.sub-menu {
  background-color: #191c24;
  position: fixed;
  width: 300px;
  text-align: center;
  top: 70px;
  right: 0;
  z-index: 10;
  padding-bottom: 10px;
  cursor: pointer;
}

.sub-menu > .member_name {
  padding-bottom: 20px;
}

.member_name:hover {
  color: #6c7293;
}

.brand_name {
  color: #fff;
  font-size: 1.5rem;
  line-height: 62px;
  margin-right: 0;
  padding: 0 0 0 1.3rem;
  width: 100%;
}

.hamburger_sidebar {
  /* width: 100%; */
  color: #fff;
  padding-left: 1.3rem;
  padding-top: 1.3rem;
  cursor: pointer;
  z-index: 11;
}

.line {
  width: 30px;
  height: 3px;
  background: #fff;
  margin: 5px;
}

@media (max-width: 500px) {

  .brand_name {
    display: none;
  }

}
</style>
