<template>
  <div class="register_hero">
    <h2>Profil</h2>
    <form class="register_form" @submit.prevent="register">
      <input type="text" id="fname" name="fname" placeholder="Prénom" v-model="member.firstName" readonly/>
      <input type="text" id="lname" name="lname" placeholder="Nom" v-model="member.lastName" readonly/>
      <input type="email" id="email" name="email" placeholder="Courriel" v-model="member.email" readonly/>
      <div class="gender">
        <label class="sexe"> Homme
          <input type="radio" id="radio" name="sexe" value="homme" v-model="member.gender">
        </label>
        <label class="sexe"> Femme
          <input type="radio" id="radio" name="sexe" value="femme" v-model="member.gender">
        </label>
      </div>
      <input type="address" id="address" name="address" placeholder="Adresse" v-model="member.street"/>
      <input type="city" id="city" name="city" placeholder="Ville" v-model="member.city"/>
      <input
        type="text"
        id="postalCode"
        name="postalCode"
        placeholder="Code Postal"
        pattern="[A-Za-z][0-9][A-Za-z] [0-9][A-Za-z][0-9]"
        v-model="member.postalCode"
      />
      <input type="province" id="province" name="province" placeholder="Province" v-model="member.province"/>
      <input type="tel" id="tel" name="tel" placeholder="Numéro de téléphone" v-model="member.phoneNumber"/>
      <button class="submit_button">Enregistrer</button>
    </form>
    <modal v-if="userUpdated" v-on:closeModal="close" :message="Message" :image="imgErreur" :buttonMessage="buttonMessage" class="modal"></modal>
  </div>
</template>

<script>
import axios from 'axios';
import modal from '../Modal.vue';

export default {
  name: 'Register',
  components: {
    modal,
  },
  data() {
    return {
      member: {
        id: this.$store.getters['auth/USER_ID'],
        firstName: this.$store.getters['auth/USER_FIRSTNAME'],
        lastName: this.$store.getters['auth/USER_LASTNAME'],
        email: this.$store.getters['auth/USER_EMAIL'],
        phoneNumber: this.$store.getters['auth/USER_PHONENUMBER'],
        gender: this.$store.getters['auth/USER_GENDER'],
        street: this.$store.getters['auth/USER_STREET'],
        city: this.$store.getters['auth/USER_CITY'],
        postalCode: this.$store.getters['auth/USER_POSTALCODE'],
        province: this.$store.getters['auth/USER_PROVINCE'],
        role: this.$store.getters['auth/USER_ROLE'],
      },
      userUpdated: false,
      buttonMessage: 'Ok',
      imgErreur: false,
      Message: 'Vos changements ont bien été enregistrés',
    };
  },

  methods: {
    close() {
      this.userUpdated = false;
      this.$emit('hideProfil');
    },
    async register() {
      await this.$store.dispatch('auth/refreshSession');
      const urlLink = 'https://jesirs95zl.execute-api.us-east-1.amazonaws.com/api/members/' + this.member.id; // eslint-disable-line prefer-template
      await axios({
        method: 'put',
        url: urlLink,
        headers: {
          Authorization: localStorage.getItem('jwtToken'), // eslint-disable-line prefer-template
        },
        data: this.member,
      })
        .catch((error) => {
          console.log('Erreur: ', error);
        })
        .finally(() => {
          this.$store.commit('auth/updateUserInfo', this.member);
          // this.userUpdated = true;
          this.$router.go();
        });
    },
  },
};

</script>
<style scoped>
.register_hero {
  text-align: center;
  width: 100%;
}

h2 {
  color: white;
}

.register_form {
  background-color: white;
  border-radius: 50px;
  padding: 5rem 5rem 2rem 5rem;
  width: auto;
  min-width: auto;
  margin: auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.register_form label {
  margin: 5px 30px 5px 0;
  color: #aaa;
  font-size: 1.2em;
  line-height: 1.5em;
}

input {
  width: 100%;
  border: none;
  border-bottom: 2px solid #ebebeb;
  margin-bottom: 31px;
  font-family: inherit;
  color: #aaa;
  font-size: 1.2em;
  line-height: 1.5em;
}

p {
  color: #aaa;
  font-size: 1.2em;
  line-height: 1.5em;
}

.submit_button {
  margin-top: 1rem;
  background-color: #006100;
  color: #fff;
  font-size: 1.35em;
  display: inline-block;
  text-align: center;
  padding: 0 2.2em;
  height: 2.15em;
  font-weight: 700;
  cursor: pointer;
  border-radius: 4px;
  border: none;
}

.gender {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.modal {
  margin-left: 150px;
}

 @media (min-width: 500px) {
  .register_form {
    width: 500px;
    min-width: 500px;
  }
 }
</style>
