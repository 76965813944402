<template>
<nav-bar/>
    <header>
      <div class="project_container">
            <div class="title_project">
              <h2>Le projet Madnoom</h2>
            </div>
              <div class="description">
                <h3>Le début ...</h3>
                  <p>L’histoire Madnoom a débuté il y a plus d’un an après une rencontre dans une salle de réunion d’un hôtel des Laurentides. Nous avons discuté longuement pendant cette rencontre des difficultés de bien s’alimenter.
                    Que ce soit dû au manque de temps, au marketing des aliments ou, dans mon cas, au manque de connaissance dans ce domaine, nous avons convenu que chacun tente de s’alimenter du mieux qu’il peut. Mais est-ce suffisant?
                    La réponse est évidente lorsque nous regardons tous les problèmes de santé dont souffre la population. </p>
              </div>
              <div class="description">
                <div class="fondateur">
                  <img src="../assets/img/jo.jpg" alt="test" class="fondateur_image"/>
                  <div class="caption">Jonathan, fondateur de Madnoom</div>
                </div>
                <h3>Quels sont les problèmes?</h3>
                <p>Le projet vise à solutionner deux problèmes : </p>
                <ul>
                  <li>1- La difficulté d’accéder à de la viande de qualité…</li>
                  <li>2- Un service de livraison ayant des standards toujours moins élevé…</li>
                </ul>
              </div>
              <div class="description ">
                <h3>Accès à de la viande de qualité.</h3>
                <p>Un mouvement de plus en plus important à vue le jour ces dernières années : les animaux nourris exclusivement d’herbe ou élevé en pâturage. Puisque les animaux mangent exclusivement ce qu’ils sont supposés manger,
                  ils procurent une viande de qualité supérieure exempte de gluten, par exemple. En plus d’avoir un régime sain, ils vivent dans des conditions « humaines », ce qui donne des animaux moins stressés et donc une viande de meilleure qualité.
                  Mais où trouver cette viande? Évidemment, pas dans les magasins grande surface, mais plutôt directement chez les éleveurs.</p>
              </div>
              <div class="description">
                <h3>Un service de livraison 5 étoiles.</h3>
                <p>Êtes-vous satisfait des dernières livraisons que vous avez reçues? Pour vous donner un exemple, un livreur a déposé mon colis sur mon auto il y a quelques mois…
                  Un autre m’a klaxonner car il ne pouvait pas débarquer de son auto. La raison : il est allergique aux chiens et mon caniche de 9 livres était dehors… D’un autre côté, qu’est-ce que je peux dire …
                  j’ai le service auquel j’ai droit puisque la livraison est gratuite …. </p>
                <p>Il ne faut pas se mettre la tête dans le sable, rien n’est gratuit de nos jours. Le prix de la livraison est toujours inclus dans le prix des articles.
                  Chez Madnoom, nous avons décidé de ne pas inclure le prix de la livraison dans le prix des aliments. Nous avons encore poussé plus loin en décidant de ne pas prendre de « cote » sur le prix des aliments.
                  Nous voulons encourager les producteurs, non pas les empêcher de gagner leur vie. Madnoom charge donc un prix fixe de 20$ de frais de gestion / livraison pour vos commandes.
                  Et nous vous garantissons que la livraison sera à la hauteur de vos attentes!</p>
              </div>
              <div class="description">
                <h3>Un projet pilote</h3>
                <p>Nous débutons donc un projet-pilote de 3 mois dans la région de Mont-Laurier et Gatineau. Pour l’instant, nous offrons seulement l’excellent bœuf nourri à l’herbe de la ferme Grazing Days,
                  mais de nouvelles viandes et aliments seront ajoutés progressivement.
                  Nous sommes à la recherche de famille souhaitant avoir accès facilement à des aliments de première qualité livrés directement chez vous. La livraison se fera une fois par mois. </p>
                <p>Vous êtes intéressé? Envoyez-nous un courriel à l’adresse info@madnoom.ca et nous vous répondrons rapidement.</p>
              </div>
          </div>

        <!-- Footer -->
        <footer id="footer">
            <div class="container">
                <!-- <ul class="icons">
                    <li><a href="#" class="icon fa-facebook"></a></li>
                    <li><a href="#" class="icon fa-twitter"></a></li>
                    <li><a href="#" class="icon fa-instagram"></a></li>
                </ul> -->
                <ul class="copyright">
                    <li>&copy; Madnoom</li>
                    <li>Design: Madnoom</li>
                    <!-- <li>Images: Madnoom</li> -->
                </ul>
            </div>
        </footer>
    </header>
</template>

<script>
import NavBar from '../components/UI/NavBar.vue';

export default {
  components: {
    NavBar,
  },
};
</script>

<style scoped>

.project_container {
  padding: 7em 0 1em 0;
  background-image: url("../assets/img/overlay.png"), url("../assets/img/background.jpg");
}
.title_project {
  text-align: center;
  padding-left: 3rem;
  line-height: 1.75;
}

.title_project > h2 {
  color: #5e9c3a;
}

.description {
  margin: 2rem 3rem;
  color: #ffffff;
}

.description > p {
  text-align: left;
  line-height: 1.75;
}

.description > h3 {
  text-align: left;
  color: #5e9c3a;
}

li {
  list-style: none;
  text-align: left;
  margin: 1rem 0;
}

.fondateur {
  position: relative;
  float: right;
  margin-left: 2rem;
}

.fondateur_image {
  height: 25rem;
  border-radius: 40px;
}

.caption {
  color: #5e9c3a;
  text-align: center;
}

@media (max-width: 775px) {
  .project_container {
    padding: 2em 0 1em 0;
  }

  .description {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
    margin-top: 1rem;
  }

  p {
    margin-bottom: 0;
  }

  .fondateur {
    margin: 0;
  }
  .fondateur_image {
    height: 20rem;
  }

  .description > ul {
    margin: 0;
  }
}

</style>
