<template>
<div class="modal">
  <div class="modal_wrapper">
    <div class="description_section">
      <span class="close_icon" @click="close">&#10006;</span>
      <img :src="'/img/' + picture" alt="test" class="product_image">
      <div class="title">
        <h3>{{ title }}</h3>
      </div>
      <div class="description">
        <p>{{ longDescription }}</p>
      </div>
    </div>
    <div class="action_section">
      <div class="icon_bar">
        <div class="price">{{ price }}</div>
        <img src="../../../assets/img/cow.png" alt="test" class="icon">
        <div class="addToCart">Ajouter au panier</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'ProductModal',
  props: ['title', 'longDescription', 'price', 'picture'],
  data() {
    return {
      url: '',
    };
  },
  methods: {
    close() {
      this.$emit('closeModal');
    },
  },
  computed: {
  },
};

</script>

<style scoped>

.modal_wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  height: 800px;
  z-index: 101;
}

.description_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 25px; */
  background-color: #191c24;
  width: 100%;
  height: 80%;
  color: #fff;
}

.close_icon {
  width: 100%;
  padding-top: 10px;
  padding-right: 15px;
  text-align: right;
  font-size: 20px;
  cursor: pointer;
}

.action_section {
  background-color: #191c24;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20%;
}

.product_image {
  padding-top: 10px;
  width: 90%;
  height: 70%;
}

.description {
  width: 90%;
  line-height: 2em;
}

.icon_bar {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.price {
    background-color: #00a015;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 150%;
    padding: 0 3em;
    height: 2.75em;
    font-weight: 700;
    border-radius: 80px;
    border: none;
    line-height: 1.75em;
}

.addToCart {
    background-color: #005ce6;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 150%;
    padding: 0 1em;
    height: 2.75em;
    font-weight: 700;
    border-radius: 80px;
    border: none;
    line-height: 1.75em;
}

.icon {
    position: relative;
    width: 70px;
    height: 70px;
}

@media (max-width: 775px) {
  .modal_wrapper {
    font-size: 100%;
    width: 600px;
    height: 700px;
  }

  .icon {
    width: 50px;
    height: 50px;
  }
  .addToCart {
    font-size: 100%;
    padding: 0 1em;
    height: 2.25em;
  }

.price {
    font-size: 100%;
    padding: 0 3em;
    height: 2.25em;
}
.title {
  padding: 0;
}
.description {
  font-size: 80%;
}
}

@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape) {
  .modal_wrapper {
    top: 60%;
    left: 50%;
    transform: translate(-60%, -50%);
  }
}

@media only screen
and (min-device-width : 320)
and (max-device-width : 476)
and (orientation: landscape) {
  .modal_wrapper {
    width: 400px;
    height: 400px;
  }
}

</style>
