<template>
  <div>
    <div class="hamburger" @click="mobileNav">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
    </div>
    <nav class="main_nav">
      <h1 class="logo"> <router-link to="/">MADNOOM</router-link></h1>
      <ul class="nav-links">
        <li> <router-link to="/">Accueil</router-link></li>
        <li> <router-link to="/Product">Produits</router-link></li>
        <li> <router-link to="/projet">Projet</router-link></li>
        <li> <router-link to="/auth" v-if="!userAuth">Me Connecter</router-link></li>
        <li v-if="userAuth">
          <div class="userName" @click="displayDropDown">
            <h5>{{ USER_FIRSTNAME }} </h5>
            <img src="../../assets/img/triangle.gif" alt="test" class="icon" />
          </div>
        </li>
          <div class="dropdown" v-if="dropdown">
            <h5 class="list_option" @click="$router.push('/dashboard')">Tableau de bord</h5>
            <h5 class="list_option" @click="logout">Déconnection</h5>
          </div>
        <li> <router-link class="cta" to="/auth" v-if="!userAuth">M'inscrire</router-link></li>
      </ul>
    </nav>
    <nav class="mobile_nav" v-if="displayNav">
      <ul class="nav-links">
        <li> <router-link to="/" @click="mobileNav">Accueil</router-link></li>
        <li> <router-link to="/Product">Produits</router-link></li>
        <li> <router-link to="/projet">Projet</router-link></li>
        <li> <router-link to="/Contact" @click="$store.userLoggedIn">Contact</router-link></li>
        <li>
          <router-link to="/auth" v-if="!userAuth">Me Connecter</router-link>
          <router-link to="/dashboard" v-else>Tableau de bord</router-link>
        </li>
        <li> <router-link to="/" @click="logout" v-if="userAuth">Me déconnecter</router-link></li>
        <li> <router-link class="cta" to="/auth" @click="mobileNav"  v-if="!userAuth" >M'inscrire</router-link></li>
    </ul>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import DropdownMenuVue from './DropdownMenu.vue';

export default {
  name: 'navbar',
  components: {
    // DropdownMenuVue,
  },
  data() {
    return {
      displayNav: false,
      logedIn: false,
      dropdown: false,
    };
  },
  methods: {
    mobileNav() {
      this.displayNav = !this.displayNav;
    },
    logout() {
      this.$store.dispatch('auth/logout');
      this.$router.push({ name: 'home' });
      this.dropdown = false;
    },
    displayDropDown() {
      this.dropdown = !this.dropdown;
    },
  },
  computed: {
    ...mapGetters('auth', ['USER_FIRSTNAME']),
    userAuth() {
      return this.$store.getters['auth/IS_USER_AUTHENTICATED'];
    },
  },
};
</script>

<style scoped>

h5,
.userName {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 120%;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin: 5px 0;
  cursor: pointer;
}

.dropdown {
  /* background-color: #000; */
  position: absolute;
  top: 85%;
  right: 4%;
  width: max-content;
  padding: 0 15px;
  border-radius: 0px 0px 16px 16px;
  text-align: center;
}

.list_option {
  margin-bottom: 10px;
}

.list_option:hover {
  color: #aaa;
}
.cta {
    background-color: #f32853;
    color: #fff;
    font-size: 125%;
    display: inline-block;
    text-align: center;
    padding: 0 2.2em;
    height: 1.75em;
    font-weight: 700;
    cursor: pointer;
    border-radius: 4px;
    border: none;
    line-height: 1.75em;
}

.icon {
  width: 10px;
  height: 10px;
  margin-left: 10px;
}

</style>
