import { Auth } from 'aws-amplify';
import axios from 'axios';

export default {

  async signIn(context, payload) {
    try {
      const user = await Auth.signIn(payload.username, payload.password);
      context.commit('login');
      localStorage.setItem('email', user.attributes.email);
      localStorage.setItem('jwtToken', user.signInUserSession.accessToken.jwtToken);
      localStorage.setItem('expiration', user.signInUserSession.accessToken.payload.exp);
      localStorage.setItem('refreshToken', user.signInUserSession.refreshToken);
      localStorage.setItem('firstName', user.attributes.name.split(' ')[0]);
      localStorage.setItem('lastName', user.attributes.family_name);

      const result = await axios({
        method: 'get',
        url: 'https://jesirs95zl.execute-api.us-east-1.amazonaws.com/api/members/email',
        params: {
          email: localStorage.getItem('email'),
        },
        headers: {
          Authorization: localStorage.getItem('jwtToken'), // eslint-disable-line prefer-template
        },
      })
        .then((response) => {
          console.log(response);
          localStorage.setItem('Id', response.data.id);
          localStorage.setItem('Gender', response.data.gender);
          localStorage.setItem('Street', response.data.street);
          localStorage.setItem('City', response.data.city);
          localStorage.setItem('PostalCode', response.data.postalCode);
          localStorage.setItem('Province', response.data.province);
          localStorage.setItem('PhoneNumber', response.data.phoneNumber);
          localStorage.setItem('Role', response.data.role);
          context.commit('setUser');
          return 'userAlreadyInDB';
        })
        .catch((error) => {
          if (error.response.data.message === 'Email not found') {
            context.commit('setUserPrimaryInfo');
            console.log('EmailNotFound');
            return 'emailNotFoundInDB';
          }
          return 'API error not monitored';
        });
      return result;
    } catch (error) {
      console.log("Une erreur dans l'enregistrement est survenu: ", error);
      return error;
    }
  },

  async signup(context, user) {
    const fullname = user.firstname.concat(' ', user.lastname);
    try {
      return await Auth.signUp({
        username: user.username,
        password: user.password,
        attributes: {
          name: fullname,
          family_name: user.lastname,
        },
      })
        .then((data) => {
          console.log('Data', data);
          return data;
        });
    } catch (error) {
      console.log("Erreur dans le processus d'enregistrement", error);
      return error;
    }
  },

  async confirmSignup(context, user) {
    try {
      console.log('Calling confirmSigUp');
      return await Auth.confirmSignUp(user.username, user.code)
        .then((data) => {
          console.log(data);
          return data;
        })
        .finally(() => {
          console.log('SignIn', user);
          // context.dispatch('signIn', user)
        });
    } catch (erreur) {
      console.log(erreur);
      return erreur;
    }
  },

  async forgotPassword(context, username) {
    return Auth.forgotPassword(username)
      .then((data) => {
        console.log(data);
        return data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  },

  async recoverPassword(context, payload) {
    return Auth.forgotPasswordSubmit(payload.username, payload.code, payload.password)
      .then((data) => {
        console.log(data);
        return data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  },

  async sendNewCode(context, username) {
    return Auth.resendSignUp(username)
      .then((data) => {
        console.log(data);
        return data;
      })
      .catch((erreur) => {
        console.log(erreur);
        return erreur;
      });
  },

  // async login(context) {
  //   const attributes = await Auth.currentAuthenticatedUser();
  //   localStorage.setItem('email', attributes.attributes.email);
  //   localStorage.setItem('jwtToken', attributes.signInUserSession.accessToken.jwtToken);
  //   localStorage.setItem('expiration', attributes.signInUserSession.accessToken.payload.exp);
  //   localStorage.setItem('refreshToken', attributes.signInUserSession.refreshToken);
  //   localStorage.setItem('firstName', attributes.attributes.name.split(' ')[0]);
  //   localStorage.setItem('lastName', attributes.attributes.family_name);

  //   console.log('Appel Get');
  //   await axios({
  //     method: 'get',
  //     url: 'https://jesirs95zl.execute-api.us-east-1.amazonaws.com/api/members/email',
  //     params: {
  //       email: localStorage.getItem('email'),
  //     },
  //     headers: {
  //       Authorization: localStorage.getItem('jwtToken'), // eslint-disable-line prefer-template
  //     },
  //   })
  //     .then((response) => {
  //       console.log(response);
  //       localStorage.setItem('Id', response.data.id);
  //       localStorage.setItem('Gender', response.data.gender);
  //       localStorage.setItem('Street', response.data.street);
  //       localStorage.setItem('City', response.data.city);
  //       localStorage.setItem('PostalCode', response.data.postalCode);
  //       localStorage.setItem('Province', response.data.province);
  //       localStorage.setItem('PhoneNumber', response.data.phoneNumber);
  //       localStorage.setItem('Role', response.data.role);
  //       context.commit('setUser');
  //       context.commit('login');
  //     })
  //     .catch((error) => {
  //       if (error.response.data.message === 'Email not found') {
  //         context.commit('setUserPrimaryInfo');
  //       }
  //     });
  // },

  tryLogin(context) {
    const token = localStorage.getItem('jwtToken');
    const email = localStorage.getItem('email');

    if (token && email) {
      context.commit('setUser');
      context.commit('login');
    }
  },

  async logout(context) {
    console.log('Logout');
    try {
      await Auth.signOut();
      context.commit('resetUserPersonalInfo');
      localStorage.removeItem('jwtToken');
      localStorage.removeItem('email');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('Gender');
      localStorage.removeItem('City');
      localStorage.removeItem('Role');
      localStorage.removeItem('PostalCode');
      localStorage.removeItem('PhoneNumber');
      localStorage.removeItem('lastName');
      localStorage.removeItem('firstName');
      localStorage.removeItem('Province');
      localStorage.removeItem('phoneNumber');
      localStorage.removeItem('Street');
      localStorage.removeItem('expiration');
      localStorage.removeItem('Id');
    } catch (error) {
      console.log('error signing out: ', error);
    }
    context.commit('logout');
  },

  async refreshSession(dispatch) {
    if (localStorage.getItem('Role') !== 'undefined') {
      await Auth.currentSession()
        .then((data) => {
          localStorage.setItem('jwtToken', data.accessToken.jwtToken);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      dispatch.logout();
    }
  },
};
