<template>
  <nav-bar />
  <div class="register_hero">
    <h2>Enregistrement</h2>
    <p>Veuillez svp entrer les informations suivantes: </p>
    <form class="register_form" @submit.prevent="register">
      <input type="text" id="fname" name="fname" placeholder="Prénom" v-model="member.firstName" readonly/>
      <input type="text" id="lname" name="lname" placeholder="Nom" v-model="member.lastName" readonly/>
      <input type="email" id="email" name="email" placeholder="Courriel" v-model="member.email" readonly/>
      <div class="gender">
        <label class="sexe"> Homme
          <input type="radio" id="radio" name="sexe" value="homme" v-model="member.gender">
        </label>
        <label class="sexe"> Femme
          <input type="radio" id="radio" name="sexe" value="femme" v-model="member.gender">
        </label>
      </div>
      <input type="address" id="address" name="address" placeholder="Adresse" v-model="member.street"/>
      <input type="city" id="city" name="city" placeholder="Ville" v-model="member.city"/>
      <input
        type="text"
        id="postalCode"
        name="postalCode"
        placeholder="Code Postal"
        pattern="[A-Za-z][0-9][A-Za-z] [0-9][A-Za-z][0-9]"
        v-model="member.postalCode"
      />
      <input type="province" id="province" name="province" placeholder="Province" v-model="member.province"/>
      <input type="tel" id="tel" name="tel" placeholder="Numéro de téléphone" v-model="member.phoneNumber"/>
      <button class="submit_button">Enregistrer</button>
    </form>
  </div>
  <div class="loader" v-if="loading"></div>
  <modal v-if="userExist" v-on:closeModal="this.userExist = false" :message="errMessage" :image="imgErreur" :buttonMessage="buttonErrMessage"></modal>
  <modal v-if="userCreated" v-on:closeModal="this.$router.push('/')" :message="successMessage" :buttonMessage="buttonSuccessMessage"></modal>

</template>

<script>
import axios from 'axios';
import Modal from '../components/UI/Modal.vue';
import NavBar from '../components/UI/NavBar.vue';

export default {
  name: 'Register',
  components: {
    Modal,
    NavBar,
  },
  data() {
    return {
      email: '',
      firstName: '',
      lastName: '',
      axiosResponse: '',
      axiosError: '',
      userExist: false,
      userCreated: false,
      member: {
        id: null,
        firstName: this.$store.getters['auth/USER_FIRSTNAME'],
        lastName: this.$store.getters['auth/USER_LASTNAME'],
        email: this.$store.getters['auth/USER_EMAIL'],
        phoneNumber: '',
        gender: '',
        street: '',
        city: '',
        postalCode: '',
        province: '',
        role: 'Member',
      },
      loading: false,
      imgSource: '1',
      errMessage: "L'adresse courriel est déjà présente dans notre base de données. Veuillez recommencer.",
      successMessage: "Merci de vous être enregistré, quelqu'un communiquera avec vous sous peu.",
      buttonErrMessage: 'Recommencer',
      buttonSuccessMessage: 'Continuer',
      imgErreur: false,
      test: '',
    };
  },

  methods: {
    async register() {
      await this.$store.dispatch('auth/refreshSession');
      await axios({
        method: 'post',
        url: 'https://jesirs95zl.execute-api.us-east-1.amazonaws.com/api/members',
        headers: {
          Authorization: localStorage.getItem('jwtToken'), // eslint-disable-line prefer-template
        },
        data: this.member,
      })
        .then(() => {
          console.log('Enregistrement en cours');
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$store.commit('auth/updateUserInfo', this.member);
          this.loading = false;
          this.$router.go();
        });
    },
  },
};

</script>
<style scoped>
.register_hero {
  padding: 10em 0 13em 0;
  height: 150vh;
  /* margin-bottom: 5em; */
  background-attachment: fixed;
  background-image: url("../assets/img/overlay.png"), url("../assets/img/bbq.jpg");
  background-position: center top;
  background-size: cover;
  line-height: 1.75;
  text-align: center;
  /* filter: blur(8px);
  -webkit-filter: blur(8px); */
}

h2 {
  color: white;
}

.register_form {
  background-color: white;
  /* background-color: rgba(0,0,0,0.8); */
  border-radius: 50px;
  padding: 5rem 5rem 2rem 5rem;
  width: auto;
  min-width: auto;
  margin: auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.register_form label {
  margin: 5px 30px 5px 0;
  color: #aaa;
  font-size: 1.2em;
  line-height: 1.5em;
}

input {
  width: 100%;
  border: none;
  border-bottom: 2px solid #ebebeb;
  margin-bottom: 31px;
  font-family: inherit;
  color: #aaa;
  font-size: 1.2em;
  line-height: 1.5em;
}

p {
  color: #aaa;
  font-size: 1.2em;
  line-height: 1.5em;
}

.submit_button {
  margin-top: 1rem;
  background-color: #006100;
  color: #fff;
  font-size: 1.35em;
  display: inline-block;
  text-align: center;
  padding: 0 2.2em;
  height: 2.15em;
  font-weight: 700;
  cursor: pointer;
  border-radius: 4px;
  border: none;
}

.loader{
    position: absolute;
    top:0px;
    right:0px;
    width:100%;
    height:100%;
    background-color:#eceaea;
    background-image: url('../assets/img/Spinner.gif');
    background-size: 50px;
    background-repeat:no-repeat;
    background-position:center;
    z-index:10000000;
    opacity: 0.4;
    filter: alpha(opacity=40);
}

.gender {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

 @media (min-width: 500px) {
  .register_form {
    width: 500px;
    min-width: 500px;
  }

  @media (min-height: 1000px) {
  .register_hero {
    height: 100vh;
  }
  }
 }
</style>
