<template>
<div class="notImplemented">
  <h2>Section en construction</h2>
</div>
</template>

<style scoped>
.notImplemented {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;
}
</style>
