<template>
    <div
    :class="{ page_wrapper: displaySidebar, page_wrapper_nosidebar:!displaySidebar }">
      <div class="side_menu" v-if="displaySidebar">
        <side-menu  @showMemberList="memberList" @showProductList="productList" @showAddProduct="addProduct" @showProductListMember="productListMember" @hideSideBar="hideSidebar" @dashboard="dashboard" @notImplemented="notImplementedYet"/>
      </div>
        <top-nav-bar @showProfile="profileItem" :displaySidebar="displaySidebar" @showSidebar="showSidebar"/>
        <div
          :class="{main_panel: displaySidebar, main_panel_noSidebar:!displaySidebar }">
          <div
          :class="{ content_wrapper: displaySidebar, content_wrapper_noSidebar:!displaySidebar }">
              <member-list v-if="this.display === 'MemberList'"/>
              <product-list v-if="this.display === 'ProductList'"/>
              <product-list-member v-else-if="this.display === 'Product'" />
              <profile v-if="this.display === 'Profile'" @hideProfil="hide" />
              <add-product v-if="this.display === 'AddProduct'" />
              <not-implemented v-if="this.display === 'NotImplemented'"/>
              <calendar v-if="display === 'Dashboard'" />
          </div>
        </div>
    </div>
</template>

<script>
import sideMenu from '../components/UI/dashboard/SideMenu.vue';
import topNavBar from '../components/UI/dashboard/TopNavBar.vue';
import MemberList from '../components/UI/dashboard/MemberList.vue';
import ProductList from '../components/UI/dashboard/manageProduct.vue';
import ProductListMember from '../components/UI/dashboard/productList.vue';
import Profile from '../components/UI/dashboard/profile.vue';
import AddProduct from '../components/UI/dashboard/addProduct.vue';
import NotImplemented from '../components/UI/dashboard/notImplemented.vue';
import Calendar from '../components/UI/dashboard/calendar.vue';

export default {
  components: {
    sideMenu,
    topNavBar,
    MemberList,
    ProductList,
    ProductListMember,
    Profile,
    AddProduct,
    NotImplemented,
    Calendar,
  },

  data() {
    return {
      display: 'Dashboard',
      displaySidebar: true,
    };
  },

  methods: {
    memberList() {
      this.display = 'MemberList';
    },

    productList() {
      this.display = 'ProductList';
    },

    productListMember() {
      this.display = 'Product';
    },

    profileItem() {
      this.display = 'Profile';
    },

    addProduct() {
      this.display = 'AddProduct';
    },

    dashboard() {
      this.display = 'Dashboard';
    },

    hide() {
      this.display = '';
    },

    notImplementedYet() {
      this.display = 'NotImplemented';
    },

    hideSidebar() {
      this.displaySidebar = false;
    },
    showSidebar() {
      this.displaySidebar = true;
    },
  },
};
</script>

<style scoped>

p {
    color: #fff;
}
.page_wrapper {
    background-color: #000;
    /* overflow: auto; */
    display: flex;
    position: relative;
    min-height: calc(100vh - 70px);
    /* width: calc(100vh - 265px); */
    padding-left: 0;
    padding-right: 0;
    /* Change */
    flex-basis: 100%;
}

.page_wrapper_nosidebar {
    background-color: #000;
    display: flex;
    justify-content: center;
    position: relative;
    min-height: calc(100vh - 70px);
    padding-left: 0;
    padding-right: 0;
    flex-basis: 100vw;
}

.side_menu {
  /* width: 300px; */
  /* Change */
  position: fixed;
  flex-basis: 300px;
  background-color: #191c24;
  z-index: 2;
}

.main_panel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 300px;
  right: 0;
  overflow: auto;
  background-color: #000;
  margin-top: 70px;
  width: calc(100vw - 300px);
  height: calc(100vh - 70px);
}

.main_panel_noSidebar {
  width: 100%;
  overflow: auto;
  background-color: #000;
  margin-top: 70px;
  height: calc(100vh - 70px);
}

.content_wrapper {
  position: relative;
  background: #000;
  padding: 1.875rem 1.75rem;
  width: 100%;
  height: 100%;
}

.content_wrapper_noSidebar {
  width: 100vw;
  background: #000;
  padding: 1.875rem 1.75rem;
}

.title {
    display: flex;
    justify-content: flex-start;
    font-weight: 500;
    color: #fff;
    margin-left: 30px;
}

 @media (max-width: 500px) {
  .main-panel {
    width: 100vw;
  }

  .content-wrapper {
    padding: 1.875rem 0;
  }
 }
</style>
