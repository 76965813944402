<template>
  <div class="register_hero">
  <h2>Ajouter un produit</h2>
    <div class="edit_wrapper">
      <form class="register_form" @submit.prevent="register">
        <div class="category">
          <label>Produit</label>
          <input type="text" id="name" name="name" placeholder="Nom du produit" v-model="addedProduct.name"/>
        </div>
        <div class="category">
          <label>Producteur</label>
          <input type="text" id="supplier" name="supplier" placeholder="Producteur"  v-model="addedProduct.supplier"/>
        </div>
        <div class="category">
          <label>Description (courte)</label>
          <textarea id="shortDescription" name="shortDescription" placeholder="Description courte"  v-model="addedProduct.shortDescription"/>
        </div>
        <div class="category">
          <label>Description (longue)</label>
          <textarea id="longDescription" name="longDescription" placeholder="Description longue" v-model="addedProduct.longDescription"/>
        </div>
        <div class="category">
          <label>Titre</label>
          <input type="text" id="title" name="title" placeholder="Titre" v-model="addedProduct.title"/>
        </div>
        <div class="category">
          <label>Type</label>
          <input type="text" id="type" name="type" placeholder="Type de produit" v-model="addedProduct.type"/>
        </div>
        <div class="category">
          <label>Photo HD</label>
          <input type="tel" id="hdPics" name="hdPics" placeholder="Photo HD" v-model="addedProduct.picture"/>
        </div>
        <div class="category">
          <label>Photo LD</label>
          <input type="tel" id="thumbnails" name="thumbnails" placeholder="Photo basse résolution" v-model="addedProduct.thumbnails"/>
        </div>
        <div class="category">
          <label>Prix</label>
          <input type="tel" id="price" name="price" placeholder="Prix" v-model="addedProduct.price"/>
        </div>
        <div class="category">
          <label>Quantité</label>
          <input type="tel" id="quantity" name="quantity" placeholder="Quantité"  v-model="addedProduct.quantity"/>
        </div>
        <div class="category">
          <label>Status</label>
          <input type="tel" id="status" name="status" placeholder="Statut"  v-model="addedProduct.status"/>
        </div>
        <div class="button">
          <button class="submit_button">Enregistrer</button>
          <button class="cancel_button" @click="closeModal">Annuler</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
// import modal from '../Modal.vue';

export default {
  name: 'addProduct',
  components: {
    // modal,
  },
  data() {
    return {
      addedProduct: {
        name: '',
        supplier: '',
        shortDescription: '',
        longDescription: '',
        title: '',
        type: '',
        picture: '',
        thumbnails: '',
        price: '',
        quantity: '',
        status: '',
      },
    };
  },

  methods: {
    // close() {
    //   this.userUpdated = false;
    //   this.$emit('hideProfil');
    // },
    async register() {
      await this.$store.dispatch('auth/refreshSession');
      console.log('Added product: ', this.addedProduct);
      const urlLink = 'https://jesirs95zl.execute-api.us-east-1.amazonaws.com/api/products/'; // eslint-disable-line prefer-template
      await axios({
        method: 'post',
        url: urlLink,
        headers: {
          Authorization: localStorage.getItem('jwtToken'), // eslint-disable-line prefer-template
          'Content-Type': 'application/json',
        },
        data: this.addedProduct,
      })
        .catch((error) => {
          console.log('Erreur: ', error);
        })
        .finally(() => {
          // this.$store.commit('auth/updateUserInfo', this.member);
          // this.userUpdated = true;
          this.$router.go();
        });
    },
  },
};

</script>
<style scoped>
.register_hero {
  text-align: center;
  width: 100%;
}

h2 {
  color: white;
}

.register_form {
  background-color: white;
  border-radius: 50px;
  padding: 5rem 5rem 2rem 5rem;
  width: 80%;
  max-width: 1300px;
  min-width: 500px;
  margin: auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.register_form label {
  margin: 5px 30px 5px 0;
  color: #aaa;
  font-size: 1.2em;
  line-height: 1.5em;
}

input {
  width: 100%;
  border: none;
  border-bottom: 2px solid #ebebeb;
  margin-bottom: 31px;
  font-family: inherit;
  color: #aaa;
  font-size: 1.2em;
  line-height: 1.5em;
}

p {
  color: #aaa;
  font-size: 1.2em;
  line-height: 1.5em;
}

.button {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.submit_button {
  margin-top: 1rem;
  background-color: #006100;
  color: #fff;
  font-size: 1.35em;
  display: inline-block;
  text-align: center;
  padding: 0 2.2em;
  height: 2.15em;
  font-weight: 700;
  cursor: pointer;
  border-radius: 4px;
  border: none;
}

.cancel_button {
  margin-top: 1rem;
  background-color: #F72C2C;
  color: #fff;
  font-size: 1.35em;
  display: inline-block;
  text-align: center;
  padding: 0 1.2em;
  height: 2.15em;
  font-weight: 700;
  cursor: pointer;
  border-radius: 4px;
  border: none;
}

.category {
  width: 100%;
  display: flex;
}

label {
  width: 15%;
  font-weight: bold;
}

textarea {
  font-family: inherit;
  font-size: inherit;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  height: 100px;
}

 @media (max-width: 500px) {

.edit_wrapper {
  width: 100%;
}
.register_form {
  width: 100%;
  padding: 5rem 0;
  margin: 0;
  min-width: 0;
  }
.register_form label {
  font-size: 1em;
}

input {
  font-size: 1em;
}
 }
</style>
