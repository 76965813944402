<template>
    <section class="app_sidebar">
        <nav id="sidebar" class="sidebar">
        <div class="side_menu">
            <!-- <div class="brand_name">Madnoom</div> -->
        </div>
        <ul class="nav" v-if="showSidebar">
            <li class="nav-item nav_profile">
                <div class="profile_desc">
                    <div class="profile_pic">
                        <img src="../../../assets/img/man.gif" alt="test" class="profile_icon" v-if="this.gender === 'homme'"/>
                        <img src="../../../assets/img/woman.gif" alt="test" class="profile_icon" v-if="this.gender === 'femme'"/>
                        <div class="profile_name">
                            <h5 class="member_name">{{ userName }}</h5>
                            <span class="nav-link">{{ USER_ROLE }}</span>
                        </div>
                    </div>
                </div>
            </li>
            <div class="close_section" v-if="showSidebar" @click="hideSideBar">
                <img src="../../../assets/img/left-arrow.png" alt="test" class="close_icon" @click="hideSideBar"/>
            </div>
            <li class="nav-item nav-category">
                <span class="nav-link">Navigation</span>
            </li>
            <li class="nav-item menu-items" @click="this.dashboard">
                <span class="menu-icon">
                    <img src="../../../assets/img/dashboard.png" alt="test" class="icon"/>
                </span>
                <a ref="/home" class="item-list">Tableau de bord</a>
            </li>
            <li class="nav-item menu-items" @click="this.notImplemented">
                <span class="menu-icon">
                    <img src="../../../assets/img/groceries.png" alt="test" class="icon"/>
                </span>
                <a ref="/home" class="item-list">Prochaine commande</a>
            </li>
            <li class="nav-item menu-items" @click="this.notImplemented">
                <span class="menu-icon">
                    <img src="../../../assets/img/delivery-truck.png" alt="test" class="icon" />
                </span>
                <a ref="/home" class="item-list">Commandes passées</a>
            </li>
            <li class="nav-item menu-items" @click="this.displayProductList" v-if="USER_ROLE === 'Admin'">
                <span class="menu-icon">
                    <img src="../../../assets/img/clipboard.png" alt="test" class="icon"/>
                </span>
                <a ref="/home" class="item-list">Liste des produits</a>
            </li>
            <li class="nav-item menu-items" @click="this.displayProductListMember" v-if="USER_ROLE === 'Member'">
                <span class="menu-icon">
                    <img src="../../../assets/img/clipboard.png" alt="test" class="icon"/>
                </span>
                <a ref="/home" class="item-list">Liste des produits</a>
            </li>
            <li class="nav-item menu-items" @click="this.displayAddProduct" v-if="USER_ROLE === 'Admin'">
                <span class="menu-icon">
                    <img src="../../../assets/img/add.png" alt="test" class="icon"/>
                </span>
                <a ref="/home" class="item-list">Ajouter un produit</a>
            </li>
            <li class="nav-item menu-items" @click="this.displayMemberList" v-if="USER_ROLE === 'Admin'">
                <span class="menu-icon">
                    <img src="../../../assets/img/add-user.png" alt="test" class="icon"/>
                </span>
                <a ref="/home" class="item-list">Liste des membres</a>
            </li>
        </ul>
        </nav>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  emits: ['dashboard', 'showMemberList', 'showProductList', 'showAddProduct', 'showProductListMember', 'hideSideBar', 'notImplemented'],
  data() {
    return {
      memberList: false,
      firstname: '',
      gender: this.$store.getters['auth/USER_GENDER'],
      showSidebar: true,
    };
  },
  computed: {
    ...mapGetters('auth', ['USER_ROLE']),
    userName() {
      return this.$store.getters['auth/USER_FIRSTNAME'] + ' ' + this.$store.getters['auth/USER_LASTNAME']; // eslint-disable-line prefer-template
    },
  },

  methods: {
    displayMemberList() {
      this.$emit('showMemberList');
    },
    displayProductList() {
      this.$emit('showProductList');
    },
    displayProductListMember() {
      this.$emit('showProductListMember');
    },
    displayAddProduct() {
      this.$emit('showAddProduct');
    },
    displaySideBar() {
      this.showSidebar = true;
      this.$emit('showSideBar');
    },
    hideSideBar() {
      this.showSidebar = false;
      this.$emit('hideSideBar');
    },
    dashboard() {
      this.$emit('dashboard');
    },
    notImplemented() {
      this.$emit('notImplemented');
    },
  },
};

</script>

<style scoped>

html {
    overflow-x: hidden;
    -webkit-text-size-adjust: 100%;
}

body {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    text-align: left;
}

a {
    font-size: 1;
}

li {
    margin: 0;
}

.app_sidebar {
    margin-top: 70px;
    display: block;
    bottom: 0;
}

.sidebar {
    background: #191c24;
    width: 300px;
    /* min-height: calc(100vh - 70px); */
    height: calc(100vh - 70px);
    padding: 0;
    z-index: 11;
}

.sidebar .nav {
    overflow: hidden;
    flex-wrap: nowrap;
    flex-direction: column;
}

.nav {
    display: flex;
    padding-left: 0;
    margin: 0;
    list-style: none;
    color: #fff;
}

.nav-item {
    line-height: 1;
}

.menu-items {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0.6rem 1.17rem;
    line-height: 1.25;
    cursor: pointer;
}

.nav-link {
    color: #6c7293;
    font-size: 14px;
    font-weight: 600;
    height: auto;
    padding-left: 0;
}

.nav-category {
    padding: 0.5rem 1.188rem;
}

.side_menu {
    background-color: #191c24;
    color: #fff;
    display: flex;
    align-items: center;

}

.profile_desc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.17rem 0.6rem 1.17rem;
    line-height: 1.25;
}

.profile_pic {
    display: flex;
    align-items: center;
}

.profile_icon {
    position: relative;
    width: 35px;
}

.profile_name {
    margin-left: 1rem;
    display: block;
    margin-right: 1rem;
    font-weight: 400;
}

.profile_name span {
    font-size: 12px;
    color: #6c7293;
    white-space: nowrap;
}

.member_name {
    white-space: nowrap;
    color: #fff;
    margin: 0;
    line-height: 1.2;
    font-size: 1rem;
}

.menu-icon {
    margin-right: 1rem;
    font-size: .8125rem;
    background-color: rgba(108,114,147,.2);
    width: 31px;
    height: 31px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.icon {
    width: 24px;
    height: 24px;
}

.item-list:hover {
  color: #6c7293;
}

.close_section {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.close_icon {
  color: #fff;
  width: 40px;
  height: 30px;
  padding-top: 10px;
  padding-right: 15px;
  text-align: right;
  font-size: 20px;
  cursor: pointer;
}

@media (max-width: 500px) {

  .nav_profile {
    display: none;
  }

}
</style>
