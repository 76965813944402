<template>
    <nav-bar />
    <div class="background">
        <product-list />
    </div>

        <!-- Footer -->
    <footer id="footer">
        <div class="container">
            <!-- <ul class="icons">
                <li><a href="#" class="icon fa-facebook"></a></li>
                <li><a href="#" class="icon fa-twitter"></a></li>
                <li><a href="#" class="icon fa-instagram"></a></li>
            </ul> -->
            <ul class="copyright">
                <li>&copy; Madnoom</li>
                <li>Design: Madnoom</li>
                <!-- <li>Images: Madnoom</li> -->
            </ul>
        </div>
    </footer>
</template>

<script>
import NavBar from '../components/UI/NavBar.vue';
import ProductList from '../components/UI/dashboard/productList.vue';

export default {
  components: {
    NavBar,
    ProductList,
  },
};
</script>

<style scoped>

.background {
    padding: 10em 0 1em 0;
    background-image: url("../assets/img/overlay.png"), url("../assets/img/background.jpg");
    min-height: calc(100vh - 250px);
}

</style>
