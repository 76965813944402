<template>
    <div class="adminPage" />
    <div class="member_list">
        <h2>Liste des membres</h2>
        <div class="title_row">
          <p>ID</p>
          <p>Prénom</p>
          <p>Nom</p>
          <p>Email</p>
          <p>Numéro de téléphone</p>
          <p>Adresse</p>
        </div>
        <div v-if="!loading">
          <div v-for="elements in member._embedded.memberList" :key="elements.id" class="member_row">
            <p>{{ elements.id}}</p>
            <p>{{ elements.firstName}}</p>
            <p>{{ elements.lastName}}</p>
            <p>{{ elements.email}}</p>
            <p>{{ elements.phoneNumber}}</p>
            <p>{{ elements.street}}, {{ elements.city }}, {{ elements.postalCode }}, {{ elements.province }}</p>
          </div>
        </div>
        <div class="loader" v-if="loading"></div>
    </div>
</template>

<script>
// import axios from 'axios';

export default {
  data() {
    return {
      member: [],
      loading: true,
      display: false,
    };
  },
  methods: {},

  async created() {
    // axios({
    //   method: 'get',
    //   url: 'http://localhost:8080/api/members/email',
    //   params: {
    //     email: 'joconstantineau@hotmail.com',
    //   },
    //   // headers: {
    //   //   Authorization: this.$store.getters['auth/JWT_TOKEN'], // eslint-disable-line prefer-template
    //   //   'Content-type': 'application/json',
    //   // },
    // })
    //   .then((response) => {
    //     // this.member = response.data;
    //     console.log(response);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   })
    //   .finally(() => { this.loading = false; });
  },
};
//   axios
//     // .get('https://jesirs95zl.execute-api.us-east-1.amazonaws.com/api/members', this.member, {})
//     .get('http://localhost:8080/api/members', this.member, {})
//     .then((response) => {
//       this.member = response.data;
//     })
//     .catch((err) => console.error(err.message))
//     .finally(() => { this.loading = false; });
//   },
// };
</script>

<style scoped>

.adminPage {
    padding: 7em 0 1em 0;
    background-image: url("../assets/img/overlay.png"), url("../assets/img/contactus.jpg");
}

.member_list {
  text-align: center;
}

.title_row {
  background-color: black;
  display: grid;
  grid-template-columns: repeat(5, 1fr) 2fr;
}

.member_row {
  display: grid;
  grid-template-columns: repeat(5, 1fr) 2fr ;
}

p {
    color: #aaa;
    font-size: 1.2em;
    line-height: 1.5em;
}

.loader{
    position: absolute;
    top:0px;
    right:0px;
    width:100%;
    height:100%;
    background-color:#eceaea;
    background-image: url('../assets/img/Spinner.gif');
    background-size: 50px;
    background-repeat:no-repeat;
    background-position:center;
    z-index:10000000;
    opacity: 0.4;
    filter: alpha(opacity=40);
}
</style>
