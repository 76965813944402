<template>
<div class="product_list">
    <div class="title_row">
        <div class="headers">
            <p>ID</p>
            <!-- <span>&#8593;</span>
            <span>&#8595;</span> -->
        </div>
        <div class="headers">
            <p>Nom</p>
            <!-- <span>&#8593;</span>
            <span>&#8595;</span> -->
        </div>
        <div class="headers">
            <p>Producteur</p>
            <!-- <span>&#8593;</span>
            <span>&#8595;</span> -->
        </div>
        <div class="headers">
            <p>Description courte</p>
            <!-- <span>&#8593;</span>
            <span>&#8595;</span> -->
        </div>
         <div class="headers">
            <p>Description longue</p>
            <!-- <span>&#8593;</span>
            <span>&#8595;</span> -->
        </div>
        <div class="headers phoneNumber">
            <p>Titre</p>
            <!-- <span>&#8593;</span>
            <span>&#8595;</span> -->
        </div>
        <div class="headers">
            <p>Prix</p>
            <!-- <span>&#8593;</span>
            <span>&#8595;</span> -->
        </div>
        <div class="headers">
            <p>Type</p>
            <!-- <span>&#8593;</span>
            <span>&#8595;</span> -->
        </div>
        <div class="headers">
            <p>Nom de la photo</p>
            <!-- <span>&#8593;</span>
            <span>&#8595;</span> -->
        </div>
        <div class="headers">
            <p>Nom du thumbnail</p>
            <!-- <span>&#8593;</span>
            <span>&#8595;</span> -->
        </div>
        <div class="headers">
            <p>Quantité</p>
            <!-- <span>&#8593;</span>
            <span>&#8595;</span> -->
        </div>
        <div class="headers">
            <p>Status</p>
            <!-- <span>&#8593;</span>
            <span>&#8595;</span> -->
        </div>
        <div class="headers">
            <p>Date de création</p>
            <!-- <span>&#8593;</span>
            <span>&#8595;</span> -->
        </div>
        <div class="headers">
            <p>Action</p>
            <!-- <span>&#8593;</span>
            <span>&#8595;</span> -->
        </div>
    </div>
    <div v-if="!loading">
        <div v-for="elements in products._embedded.productList" :key="elements.id" class="product_row">
            <div class="product_id">
                <p> {{ elements.id }}</p>
            </div>
            <div class="product">
                <p>{{ elements.name }}</p>
            </div>
            <div class="product">
                <p>{{ elements.supplier}}</p>
            </div>
            <div class="product">
                <p>{{ elements.shortDescription}}</p>
            </div>
            <div class="product">
                <p>{{ elements.longDescription}}</p>
            </div>
            <div class="product">
                <p>{{ elements.title}}</p>
            </div>
            <div class="product">
                <p>{{ elements.price}}$</p>
            </div>
            <div class="product">
                <p> {{ elements.type }}</p>
            </div>
            <div class="product">
                <p> {{ elements.picture }}</p>
            </div>
            <div class="product">
                <p> {{ elements.thumbnails }}</p>
            </div>
            <div class="product">
                <p> {{ elements.quantity }}</p>
            </div>
            <div class="product">
                <p> {{ elements.status }}</p>
            </div>
            <div class="product">
                <p> {{ elements.createdDate }}</p>
            </div>
            <div class="product">
                <button class="editButton" @click="editProduct(elements)">Edit</button>
            </div>
        </div>
    </div>
    <div class="loader" v-if="loading"></div>
    <edit-product-vue v-if="editWindow" @closeModal="hideModal" :product="productToEdit"/>
</div>
</template>

<script>
// import { Auth } from 'aws-amplify';
import axios from 'axios';
import editProductVue from './editProduct.vue';

export default {
  name: 'manageProduct',
  components: {
    editProductVue,
  },
  data() {
    return {
      products: [],
      productToEdit: [],
      name: 'test22',
      loading: true,
      display: false,
      editWindow: false,
    };
  },
  methods: {
    editProduct(product) {
      this.productToEdit = product;
      this.editWindow = true;
    },
    hideModal() {
      this.editWindow = false;
    },
  },

  async created() {
    await this.$store.dispatch('auth/refreshSession');
    await axios({
      method: 'get',
      url: 'https://jesirs95zl.execute-api.us-east-1.amazonaws.com/api/products',
      headers: {
        Authorization: localStorage.getItem('jwtToken'), // eslint-disable-line prefer-template
      },
    })
      .then((response) => {
        this.products = response.data;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => { this.loading = false; });
  },
};
</script>

<style scoped>

p {
    color: #6c7293;
    margin-bottom: 0;
    margin-right: 0.8rem;
}

span {
    color: #6c7293;
}

.product_list {
    width: fit-content;
    background-color: #191c24;
}

.title_row,
.product_row {
    border-bottom: 1px solid #2c2e33;
    margin: 1rem 0;
    display: grid;
    height: auto;
    grid-template-columns:
    minmax(75px, 0.5fr) minmax(150px, 1fr) minmax(150px, 1fr)
    minmax(275px, 2fr) minmax(350px, 2fr) minmax(150px, 1fr)
    minmax(100px, 0.5fr) minmax(100px, 1fr) minmax(200px, 1.5fr)
    minmax(150px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr)
    minmax(150px, 1fr) minmax(100px, 1fr);
    grid-auto-flow: column;
}

.headers,
.product {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.product_id{
    display: flex;
    justify-content: center;
    align-items: center;
}

.editButton {
    background-color: #03256e;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 100%;
    padding: 0 2em;
    height: 2em;
    font-weight: 700;
    border-radius: 80px;
    border: none;
    line-height: 1.75em;
}

</style>
