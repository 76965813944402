<template>
  <div class="navbar_background"></div>
  <div class="grassfed">
      <div class="row_grassfed">
        <div class="wrapper">
            <div class="text">
                <img src="../assets/img/grassfed.jpg" alt="test" class="img__right"/>
                <div class="box">
                    <p>Nourri a l'herbe</p>
                </div>
                <h1>Une viande de meilleur qualité</h1>
                <span class="divider"></span>
                <p>La devise "Vous êtes ce que vous mangez" s'applique également aux vaches. Ce qu'une vache mange peut affecter de manière significative la composition en nutriments de sa viande.
                    C'est particulièrement évident lorsqu'il s'agit de la composition en acides gras. Le bœuf nourri à l'herbe contient généralement moins de graisses totales que le bœuf nourri aux céréales,
                    ce qui signifie que, gramme pour gramme, le boeuf nourri à l'herbe contient moins de calories</p>
                <h1>Meilleur goût</h1>
                <span class="divider"></span>
                <p>Un autre avantage indéniable de la viande nourrie à l'herbe est qu'elle a meilleur goût. La viande d'herbe a une saveur subtile et terreuse, attribuable à l'alimentation variée des vaches.
                    L'alimentation saine de la vache permet aux épices de ressortir plus clairement. L'ajout d'un peu de beurre donne un goût incroyable à un steak nourri à l'herbe !</p>
                <p>Le HuffPost a réalisé un test de dégustation à l'aveugle, et 100 % des dégustateurs ont pu faire la différence entre le bœuf nourri à l'herbe et le bœuf nourri au grain.
                    Tous sauf un ont préféré la saveur du bœuf nourri à l'herbe.</p>
                <div class="centered">
                    <button class="button_cta">M'inscrire</button>
                </div>
            </div>
        </div>
      </div>
  </div>

  <div class="row_grassfed">
        <div class="two_columns">
            <div class="text">
                <img src="../assets/img/free-chickens.jpg" alt="test" class="img__left"/>
                <div class="box box__left">
                    <p>Élevé en pâturage</p>
                </div>
                <h1>Des oeufs comme vous en avez jamais mangé</h1>
                <span class="divider"></span>
                <p>Par rapport aux œufs de poules commerciales, les œufs de poules élevées en pâturage contiennent deux fois plus d'acides gras oméga-3 à longue chaîne,
                    plus du double du total des acides gras oméga-3 et moins de la moitié du rapport entre les acides gras oméga-6 et les acides gras oméga-3.</p>
                <p>Les œufs de poules élevées en plein air dans des pâturages contiennent de trois à six fois plus de vitamine D. Les poules de pâturage sont exposées à la lumière directe du soleil,
                    que leur organisme transforme en vitamine D et transmet ensuite à leurs œufs. En mangeant seulement deux de ces œufs, vous obtiendrez entre 63 et 126 % de l'apport quotidien recommandé en vitamine D !</p>
                <p>Les œufs de pâturage contribuent à augmenter le taux de lipoprotéines de haute densité (HDL), ou "bon" cholestérol comme on l'appelle communément. Un taux plus élevé de HDL peut contribuer à réduire le risque de maladie cardiaque.</p>
                <p>Globalement, les œufs de pâturage sont l'un des aliments les plus nutritifs de la planète. Ils sont la multivitamine de la nature ! Les œufs de pâturage sont une riche source de sélénium, de phosphore, de vitamine B12, de cobalamine, de vitamine A, de vitamine D, de vitamine E, et plus encore !
                    Ils constituent un excellent complément à une alimentation saine et équilibrée.</p>
                <div class="centered">
                    <button class="button_cta">M'inscrire</button>
                </div>
            </div>
        </div>
    </div>

    <footer id="footer">
        <div class="container">
            <!-- <ul class="icons">
                <li><a href="#" class="icon fa-facebook"></a></li>
                <li><a href="#" class="icon fa-twitter"></a></li>
                <li><a href="#" class="icon fa-instagram"></a></li>
            </ul> -->
            <ul class="copyright">
                <li>&copy; Madnoom</li>
                <li>Design: Madnoom</li>
                <!-- <li>Images: Madnoom</li> -->
            </ul>
        </div>
</footer>

</template>

<style scoped>

.navbar_background{
    padding: 7em 0 1em 0;
    background-image: url("../assets/img/overlay.png"), url("../assets/img/contactus.jpg");
}

.grassfed {
    width: 97%;
    padding: 13em 0 2em 0;
    margin-right: auto;
    margin-left: auto;
}
.row_grassfed {
    display: flex;
    height: auto;
}

.wrapper {
    margin-top: 2rem;
}

.divider {
    width: 30px;
    background-color: #006100;
    height: 3px;
    display: inline-block;
    border-radius: 2px;
    margin: 10px 0;
}
img {
    width: 48%;
    /* min-width: 700px; */
    height: auto;
    border-radius: 10px;
    -webkit-box-shadow: 5px 5px 15px 5px #000000;
    box-shadow: 5px 5px 15px 5px #000000;
    margin: 0 1rem 2rem 1rem;
}

.img__left {
    float: left;
    margin-left: 2rem;
    margin-right: 3rem;
}

.img__right {
    float: right;
}

.box {
    background-color: #94ee94;
    padding-top: 2rem;
    display: inline-block;
    text-align: center;
    padding: 0 2.2em;
    height: 3.15em;
    font-weight: 700;
    border-radius: 4px;
    border: none;
}

p {
    color: #aaa;
    font-size: 1.2em;
    line-height: 1.5em;
}

.box p {
    color: #0b8600;
    transform: translateY(10px);
}

.centered {
    text-align: center;
    padding-bottom: 2rem;
}

/* --------- Responsive ---------- */

@media (max-width: 775px) {
    .wrapper {
        margin-top: 5rem;
    }

    .text {
        text-align: center;
    }

    .img__left,
    .img__right {
        float: none;
    }
}

</style>
