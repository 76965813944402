<template>
  <router-view />
</template>

<script>
import Amplify from 'aws-amplify';
import '@aws-amplify/ui-vue/styles.css';
import awsExports from './aws-exports';

Amplify.configure(awsExports);

export default {
  components: {
  },
  created() {
    this.$store.dispatch('auth/tryLogin');
  },
};

</script>

<style>
@import "./assets/css/main.css";
@import "./assets/css/contact.css";
@import "./assets/css/home.css";
</style>
